import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { Link, withRouter } from "react-router-dom"
import IlsLogin from "../assets/img/illustrations/ils-login.png"
import SvgLogo from "../assets/img/logo.png"
import { actionSignIn } from "../store/auth/actions"
import { pushUrl } from "../utils/url"
import TextInput from "../components/Form/TextInput"
import PasswordInput from "../components/Form/PasswordInput"
import { changeTitle } from "../utils/page"
import { APP_NAME } from "../constants"
import { canForgetPassword, canRemember, canSignUp } from "../utils/config"
import { dateTime, strtotime } from "../utils/formatter"

const SignIn = (props) => {
    const [payload, setPayload] = useState({ username: "", password: "" })
    const [errors, setErrors] = useState({})
    const [submit, setSubmit] = useState(false)
    const { loading, success, actionSignIn } = props

    useEffect(() => {
        changeTitle("Masuk")
    }, [])

    const onSubmit = (e) => {
        if (validate() === true) {
            setSubmit(true)
            actionSignIn(payload)
        }

        e.preventDefault()
    }

    const validate = () => {
        let jsonErrors = {}
        let hasError = false

        if (!payload?.username || payload?.username === "") {
            hasError = true
            jsonErrors["username"] = "Username harus diisi"
        }
        if (!payload?.password || payload?.password === "") {
            hasError = true
            jsonErrors["password"] = "Password harus diisi"
        }

        setErrors(jsonErrors)

        if (hasError === true) {
            return false
        } else {
            return true
        }
    }

    const onChange = ((field, value) => {
        payload[field] = value

        setPayload({
            ...payload,
        })
    })

    useEffect(() => {
        if (success === true) {
            window.location.href = `${process.env.REACT_APP_SUBDIR}/beranda`
        }
    }, [success])

    const [showPassword, setShowPassword] = useState(false)

    return <React.Fragment>
        <div className="authentication-inner row m-0">
            <div className="d-none d-lg-flex col-lg-7 col-xl-8 align-items-center p-5">
                <div className="w-100 d-flex justify-content-center">
                    <img src={IlsLogin} className="img-fluid" alt="Login" style={{ maxHeight: "80vh", width: "auto" }} />
                </div>
            </div>

            <div className="d-flex col-12 col-lg-5 col-xl-4 align-items-center authentication-bg p-sm-5 p-4">
                <div className="w-px-400 mx-auto">
                    <div className="app-brand mb-5">
                        <Link to={process.env.REACT_APP_SUBDIR + "/"} className="app-brand-link gap-2">
                            <span className="app-brand-logo demo">
                                <img src={SvgLogo} alt="Logo" className="logo-app" />
                            </span>
                            <span className="app-brand-text demo text-body fw-bolder main-logo-text text-primary">{APP_NAME}</span>
                        </Link>
                    </div>
                    <h4 className="mb-2">Halaman Peserta Seleksi</h4>
                    <p className="mb-4">Isi data berikut untuk masuk ke akun anda</p>

                    <form onSubmit={onSubmit} className="fv-plugins-bootstrap5">
                        <div className="mb-3">
                            <TextInput
                                id={"username"}
                                name={"username"}
                                placeholder={"Masukkan username anda"}
                                label={"Username"}
                                onChange={(e) => onChange('username', e?.target?.value)}
                                required={true}
                                value={payload?.username}
                                error={errors?.username}
                                tabIndex={1}
                            />
                            <PasswordInput
                                id={"password"}
                                name={"password"}
                                placeholder={"Masukkan password anda"}
                                label={"Password"}
                                onChange={(e) => onChange('password', e?.target?.value)}
                                required={true}
                                value={payload?.password}
                                error={errors?.password}
                                canShow={true}
                                showPassword={showPassword}
                                setShowPassword={() => setShowPassword(!showPassword)}
                                action={canForgetPassword === true ? <Link to={process.env.REACT_APP_SUBDIR + "/forget-password"} tabIndex={5}><small>Lupa Password?</small></Link> : null}
                                tabIndex={2}
                            />
                            {
                                canRemember === true ?
                                    <div className="mb-3">
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox" id="remember-me" tabIndex={3} />
                                            <label className="form-check-label" htmlFor="remember-me">
                                                Ingat Saya
                                            </label>
                                        </div>
                                    </div>
                                    : <br />
                            }
                            <button
                                tabIndex={4}
                                type="submit" className="btn btn-primary d-block w-100" disabled={submit === true && loading === true}>
                                Sign in
                                {submit === true && loading === true ? <span className="spinner-border ms-2" role="status" aria-hidden="true"></span> : null}
                            </button>
                        </div>
                    </form>

                    {
                        canSignUp === true ?
                            <p>
                                <span>Belum daftar ulang? </span>
                                <Link to={process.env.REACT_APP_SUBDIR + "/sign-up"} tabIndex={6}>
                                    <span>Daftar ulang disini</span>
                                </Link>
                            </p>
                            : null
                    }
                </div>
            </div>
        </div>
    </React.Fragment>
}

const mapStateToProps = state => {
    const { loading, success } = state.auth

    return { loading, success }
}

export default withRouter(connect(mapStateToProps, { actionSignIn })(SignIn))