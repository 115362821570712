import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { Link, withRouter } from "react-router-dom"
import UseCss from "../../components/UseCss"
import { asTimeWithLabel, dateTime, strtotime } from "../../utils/formatter"
import { changeBreadcrumbs, changeTitle } from "../../utils/page"
import { actionFetchUjian } from '../../store/ujian/actions'
import IlsPersiapan from "../../assets/img/illustrations/sitting-girl-with-laptop-light.png"
import Toast from "../../components/Toast"
import { request } from "../../utils/request"

const waktuStatic = 1681102603

const Ujian = (props) => {
    const { ujian, actionFetchUjian, accessToken, hasilUjian, success } = props

    UseCss({ src: "/assets/vendor/css/pages/page-pricing.css" })

    const [waktuMenujuUjian, setWaktuMenujuUjian] = useState(null)
    const [statusUjian, setStatusUjian] = useState("Belum Dimulai")

    const [toastUjian, setToastUjian] = useState(false)

    const [selisihWaktu, setSelisihWaktu] = useState(null)
    const [selisihWaktuLoaded, setSelisihWaktuLoaded] = useState(false)
    const [fetchCalled, setFetchCalled] = useState(false)
    const [fetchLoaded, setFetchLoaded] = useState(false)

    useEffect(() => {
        const titlePage = "Ujian"
        changeTitle(titlePage)
        changeBreadcrumbs(titlePage)

        actionGetWaktuServer()
    }, [])

    const actionGetWaktuServer = async () => {
        setSelisihWaktuLoaded(false)

        // let now = waktuStatic
        let now = parseInt((new Date).getTime() / 1000)
        // console.log(dateTime(now * 1000, "YYYY-MM-DD HH:mm:ss"))
        try {
            const res = await request(process.env.REACT_APP_SERVICE_UJIAN + '/waktu-server', {
                method: 'GET'
            })

            if (res?.success === true) {
                // setSelisihWaktu(waktuStatic + 2 - now)
                setSelisihWaktu(res?.result - now)
                setSelisihWaktuLoaded(true)
            } else {
                setSelisihWaktu(0)
                setSelisihWaktuLoaded(true)
            }
        } catch (error) {
            setSelisihWaktu(0)
            setSelisihWaktuLoaded(true)
        }
    }

    useEffect(() => {
        if (selisihWaktu !== null && fetchCalled === false) {
            setFetchCalled(true)
            actionFetchUjian({ accessToken })
        }
    }, [selisihWaktu])

    useEffect(() => {
        if (fetchCalled === true) {
            if (success !== null) {
                setFetchLoaded(true)
            }
        }
    }, [success, fetchCalled])

    const halamanUjian = (e) => {
        e.preventDefault()
        window.location.href = process.env.REACT_APP_SUBDIR + "/ujian/proses"
    }

    useEffect(() => {
        if (fetchLoaded === true) {
            // let now = waktuStatic + selisihWaktu
            let now = parseInt((new Date).getTime() / 1000) + selisihWaktu
            // console.log(dateTime(now * 1000, "YYYY-MM-DD HH:mm:ss"))

            let sisaWaktuMenujuUjian = ((strtotime(ujian?.sesiUjian?.waktu_mulai, "YYYY-MM-DD HH:mm:ss") / 1000) - now)

            setWaktuMenujuUjian(sisaWaktuMenujuUjian)
        }
    }, [ujian?.sesiUjian?.waktu_mulai, fetchLoaded])

    useEffect(() => {
        if (hasilUjian?.status === "Ujian Selesai") {
            setStatusUjian("Ujian Selesai")
            return
        }
        if (waktuMenujuUjian !== null && waktuMenujuUjian < 1) {
            // let now = waktuStatic + selisihWaktu
            let now = parseInt((new Date).getTime() / 1000) + selisihWaktu

            if (waktuMenujuUjian < 1 && now > (strtotime(ujian?.sesiUjian?.waktu_selesai, "YYYY-MM-DD HH:mm:ss") / 1000)) {
                setStatusUjian("Ujian Selesai")
                return
            } else {
                setStatusUjian("Ujian Dimulai")
                return
            }
        }
        if (waktuMenujuUjian === null) return
        if (!waktuMenujuUjian) return
        if (waktuMenujuUjian > 0 && waktuMenujuUjian < (60 * 30)) {
            setStatusUjian("Sesi Persiapan")
        }

        setToastUjian(true)

        const intervalId = setInterval(() => {
            // let now = waktuStatic + selisihWaktu
            let now = parseInt((new Date).getTime() / 1000) + selisihWaktu
            let sisaWaktuMenujuUjian = ((strtotime(ujian?.sesiUjian?.waktu_mulai, "YYYY-MM-DD HH:mm:ss") / 1000) - now)

            setWaktuMenujuUjian(sisaWaktuMenujuUjian)
        }, 1000)

        return () => clearInterval(intervalId)
    }, [waktuMenujuUjian, hasilUjian?.status])

    return <React.Fragment>
        <div className="container-xxl flex-grow-1 container-p-y">
            {
                selisihWaktuLoaded === true && fetchLoaded === true ?
                    ujian?.sesiUjian ?
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="table-responsive mt-2">
                                            <table className="table table-striped table-borderless text-center mb-5">
                                                <thead>
                                                    <tr>
                                                        <th className="text-start" scope="col" colSpan={2}>
                                                            <p className="h5 mb-4">Detail Ujian</p>
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td className="text-start" style={{ width: "30%" }}>
                                                            <strong>Tanggal Ujian</strong>
                                                        </td>
                                                        <td className="text-start" style={{ width: "70%" }}>
                                                            {dateTime(strtotime(ujian?.sesiUjian?.waktu_mulai), "YYYY-MM-DD") || '-'}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="text-start">
                                                            <strong>Waktu Ujian</strong>
                                                        </td>
                                                        <td className="text-start">
                                                            {(dateTime(strtotime(ujian?.sesiUjian?.waktu_mulai), "HH:mm") + " - " + dateTime(strtotime(ujian?.sesiUjian?.waktu_selesai), "HH:mm")) || '-'}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="text-start">
                                                            <strong>Waktu Persiapan</strong>
                                                        </td>
                                                        <td className="text-start">
                                                            {(dateTime(strtotime(ujian?.sesiUjian?.waktu_mulai) - (30 * 60000), "HH:mm") + " - " + dateTime(strtotime(ujian?.sesiUjian?.waktu_mulai), "HH:mm")) || '-'}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="text-start">
                                                            <strong>Jumlah Soal</strong>
                                                        </td>
                                                        <td className="text-start">
                                                            {ujian?.ujianPeserta?.jumlah_soal || '-'}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="text-start">
                                                            <strong>Verifikasi Wajah</strong>
                                                        </td>
                                                        <td className="text-start">
                                                            {
                                                                ujian?.ujianPeserta?.verifikasi_wajah === 1 || ujian?.ujianPeserta?.verifikasi_wajah === true ?
                                                                    <span>Sudah</span>
                                                                    :
                                                                    <span>Belum</span>
                                                            }
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="text-start">
                                                            <strong>Status Ujian</strong>
                                                        </td>
                                                        <td className="text-start">
                                                            {statusUjian}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>

                                    <div className="pricing-free-trial">
                                        <div className="position-relative">
                                            <div className="d-flex justify-content-between flex-column-reverse flex-lg-row align-items-center py-4 px-4">
                                                <div className="text-center text-lg-start mt-2 ms-3">
                                                    {
                                                        statusUjian === "Ujian Selesai" ?
                                                            <React.Fragment>
                                                                <p className="text-body mb-1">Sesi ujian telah berakhir.</p>
                                                                <Link to={process.env.REACT_APP_SUBDIR + "/hasil-ujian"} className="btn btn-success mt-4 mb-2" >Hasil Ujian</Link>
                                                            </React.Fragment>
                                                            : null
                                                    }
                                                    {
                                                        statusUjian === "Ujian Dimulai" ?
                                                            <React.Fragment>
                                                                <p className="text-body mb-1">Sesi ujian telah dimulai.</p>
                                                                {
                                                                    ujian?.ujianPeserta?.verifikasi_wajah === 1 || ujian?.ujianPeserta?.verifikasi_wajah === true ?
                                                                        <Link to={"/#"} onClick={(e) => halamanUjian(e)} className="btn btn-primary mt-4 mb-2" >Kerjakan Ujian</Link>
                                                                        :
                                                                        <Link to={process.env.REACT_APP_SUBDIR + "/ujian/persiapan"} className="btn btn-primary mt-4 mb-2" >Verifikasi Wajah</Link>
                                                                }
                                                            </React.Fragment>
                                                            : null
                                                    }
                                                    {
                                                        statusUjian === "Sesi Persiapan" ?
                                                            <React.Fragment>
                                                                <h3 className="text-primary mb-1">{asTimeWithLabel(waktuMenujuUjian, false, true)}</h3>
                                                                <p className="text-body mb-1">Sebelum sesi ujian dimulai.</p>
                                                                <p className="text-body mb-1">Sesi persiapan telah dimulai, silahkan verifikasi wajah anda.</p>
                                                                {
                                                                    ujian?.ujianPeserta?.verifikasi_wajah === 1 || ujian?.ujianPeserta?.verifikasi_wajah === true ?
                                                                        <Link to={"/#"} onClick={(e) => halamanUjian(e)} className="btn btn-primary mt-4 mb-2 disabled" disabled>Kerjakan Ujian</Link>
                                                                        :
                                                                        <Link to={process.env.REACT_APP_SUBDIR + "/ujian/persiapan"} className="btn btn-primary mt-4 mb-2" >Verifikasi Wajah</Link>
                                                                }
                                                            </React.Fragment>
                                                            : null
                                                    }
                                                    {
                                                        statusUjian === "Belum Dimulai" ?
                                                            <React.Fragment>
                                                                <h3 className="text-primary mb-1">{asTimeWithLabel(waktuMenujuUjian, false, true)}</h3>
                                                                <p className="text-body mb-1">Sebelum sesi ujian dimulai.</p>
                                                                <a href="/#" onClick={(e) => e.preventDefault()} className="btn btn-primary mt-4 mb-2 disabled" disabled>Kerjakan Ujian</a>
                                                            </React.Fragment>
                                                            : null
                                                    }
                                                </div>
                                                <img src={IlsPersiapan} className="img-fluid me-lg-5 pe-lg-1 mb-3 mb-lg-0 d-none d-lg-block" alt="Api Key Image" width="300" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        : <div className="row">
                            <div className="col-lg-12">
                                <div className="card">
                                    <div className="card-body">
                                        <small className="text-muted text-uppercase">Detail Ujian</small>
                                        <p className="mb-0 mt-2">Anda belum mendapatkan sesi ujian</p>
                                        <p className="mb-2">Silahkan tunggu proses penetapan sesi ujian oleh admin</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    :
                    <div className="auth-scan-cam-wrapper">
                        <span className="spinner-border ms-2" role="status" aria-hidden="true"></span>
                    </div>
            }

            {
                toastUjian === true && statusUjian === "Ujian Dimulai" ?
                    <Toast
                        title={"Ujian"}
                        text={"Sesi ujian telah dimulai, silahkan kerjakan."}
                    />
                    : null
            }
        </div>
    </React.Fragment>
}

const mapStateToProps = state => {
    const { accessToken } = state.auth
    const { ujian, hasilUjian, success } = state.ujian

    return {
        accessToken,
        ujian,
        hasilUjian,
        success
    }
}

export default withRouter(connect(mapStateToProps, { actionFetchUjian })(Ujian))