import React from "react"

const Toast = ({ title, text, type = "primary" }) => {
    return <React.Fragment>
        <div class={"bs-toast toast fade show bottom-0 end-0 toast-placement-ex m-4 bg-" + type} role="alert" aria-live="assertive" aria-atomic="true">
            <div class="toast-header">
                <div class="me-auto fw-semibold">{title}</div>
                {/* <small>11 mins ago</small> */}
                <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
            </div>
            <div class="toast-body">
                {text}
            </div>
        </div>
    </React.Fragment>
}

export default Toast