import Immutable from 'seamless-immutable'

const FETCH_UJIAN = 'FETCH_UJIAN'
const FETCH_UJIAN_START = 'FETCH_UJIAN_START'
const FETCH_UJIAN_END = 'FETCH_UJIAN_END'
const FETCH_UJIAN_SUCCESS = 'FETCH_UJIAN_SUCCESS'
const FETCH_UJIAN_FAILED = 'FETCH_UJIAN_FAILED'

const VERIFIKASI_UJIAN = 'VERIFIKASI_UJIAN'
const VERIFIKASI_UJIAN_START = 'VERIFIKASI_UJIAN_START'
const VERIFIKASI_UJIAN_END = 'VERIFIKASI_UJIAN_END'
const VERIFIKASI_UJIAN_SUCCESS = 'VERIFIKASI_UJIAN_SUCCESS'
const VERIFIKASI_UJIAN_FAILED = 'VERIFIKASI_UJIAN_FAILED'

const PROSES_UJIAN = 'PROSES_UJIAN'
const PROSES_UJIAN_START = 'PROSES_UJIAN_START'
const PROSES_UJIAN_END = 'PROSES_UJIAN_END'
const PROSES_UJIAN_SUCCESS = 'PROSES_UJIAN_SUCCESS'
const PROSES_UJIAN_FAILED = 'PROSES_UJIAN_FAILED'

const SELESAI_UJIAN = 'SELESAI_UJIAN'
const SELESAI_UJIAN_START = 'SELESAI_UJIAN_START'
const SELESAI_UJIAN_END = 'SELESAI_UJIAN_END'
const SELESAI_UJIAN_SUCCESS = 'SELESAI_UJIAN_SUCCESS'
const SELESAI_UJIAN_FAILED = 'SELESAI_UJIAN_FAILED'

const KIRIM_JAWABAN_UJIAN = 'KIRIM_JAWABAN_UJIAN'
const KIRIM_JAWABAN_UJIAN_SUCCESS = 'KIRIM_JAWABAN_UJIAN_SUCCESS'

const RESET_PROSES_UJIAN_SUCCESS = 'RESET_PROSES_UJIAN_SUCCESS'

const actionTypes = Immutable({
  FETCH_UJIAN,
  FETCH_UJIAN_START,
  FETCH_UJIAN_END,
  FETCH_UJIAN_SUCCESS,
  FETCH_UJIAN_FAILED,

  VERIFIKASI_UJIAN,
  VERIFIKASI_UJIAN_START,
  VERIFIKASI_UJIAN_END,
  VERIFIKASI_UJIAN_SUCCESS,
  VERIFIKASI_UJIAN_FAILED,

  PROSES_UJIAN,
  PROSES_UJIAN_START,
  PROSES_UJIAN_END,
  PROSES_UJIAN_SUCCESS,
  PROSES_UJIAN_FAILED,

  SELESAI_UJIAN,
  SELESAI_UJIAN_START,
  SELESAI_UJIAN_END,
  SELESAI_UJIAN_SUCCESS,
  SELESAI_UJIAN_FAILED,

  KIRIM_JAWABAN_UJIAN,
  KIRIM_JAWABAN_UJIAN_SUCCESS,

  RESET_PROSES_UJIAN_SUCCESS
})

export default actionTypes
