import React from "react"

const PasswordInput = ({ id = null, name, value, className = "", error = null, hint = null, placeholder = null, label = null, onChange, required = false, canShow = false, action = null, tabIndex = null, showPassword = null, setShowPassword = null }) => {
    return <React.Fragment>
        <div className={"mb-3 " + (canShow === true ? "form-password-toggle" : "") + (error ? " fv-plugins-icon-container fv-plugins-bootstrap5-row-invalid" : "")}>
            <div className="d-flex justify-content-between">
                <label htmlFor={id} className="form-label">
                    {label}
                    {required === true ? <span className="text-danger"> *</span> : null}
                </label>
                {action}
            </div>
            <div className={canShow === true ? "input-group input-group-merge" : ""}>
                <input
                    id={id}
                    type={showPassword === true ? "text" : "password"}
                    className={"form-control " + (error !== null ? "is-invalid" : "") + className}
                    placeholder={placeholder}
                    aria-label={label}
                    aria-describedby={label}
                    name={name}
                    value={value}
                    onChange={onChange}
                    tabIndex={tabIndex}
                />
                {
                    canShow === true ? <span className="input-group-text cursor-pointer" onClick={setShowPassword}><i className={showPassword === true ? "bx bx-show" : "bx bx-hide"}></i></span> : null
                }
            </div>
            {
                hint ? <div className="form-text">{hint}</div> : null
            }
            {
                error ? <div className="invalid-feedback d-block">{error}</div> : null
            }
        </div>
    </React.Fragment >
}

export default PasswordInput