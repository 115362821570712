import actionTypes from './actionTypes'

export const actionFetchUjian = (data) => {
  return {
    type: actionTypes.FETCH_UJIAN,
    data
  }
}

export const actionFetchUjianStart = () => {
  return {
    type: actionTypes.FETCH_UJIAN_START
  }
}

export const actionFetchUjianEnd = () => {
  return {
    type: actionTypes.FETCH_UJIAN_END
  }
}

export const actionFetchUjianSuccess = (data) => {
  return {
    type: actionTypes.FETCH_UJIAN_SUCCESS,
    data,
  }
}

export const actionFetchUjianFailed = () => {
  return {
    type: actionTypes.FETCH_UJIAN_FAILED
  }
}

export const actionVerifikasiUjian = (data) => {
  return {
    type: actionTypes.VERIFIKASI_UJIAN,
    data
  }
}

export const actionVerifikasiUjianStart = () => {
  return {
    type: actionTypes.VERIFIKASI_UJIAN_START
  }
}

export const actionVerifikasiUjianEnd = () => {
  return {
    type: actionTypes.VERIFIKASI_UJIAN_END
  }
}

export const actionVerifikasiUjianSuccess = (data) => {
  return {
    type: actionTypes.VERIFIKASI_UJIAN_SUCCESS,
    data,
  }
}

export const actionVerifikasiUjianFailed = () => {
  return {
    type: actionTypes.VERIFIKASI_UJIAN_FAILED
  }
}

export const actionProsesUjian = (data) => {
  return {
    type: actionTypes.PROSES_UJIAN,
    data
  }
}

export const actionProsesUjianStart = () => {
  return {
    type: actionTypes.PROSES_UJIAN_START
  }
}

export const actionProsesUjianEnd = () => {
  return {
    type: actionTypes.PROSES_UJIAN_END
  }
}

export const actionProsesUjianSuccess = (data) => {
  return {
    type: actionTypes.PROSES_UJIAN_SUCCESS,
    data,
  }
}

export const actionProsesUjianFailed = () => {
  return {
    type: actionTypes.PROSES_UJIAN_FAILED
  }
}


export const actionSelesaiUjian = (data) => {
  return {
    type: actionTypes.SELESAI_UJIAN,
    data
  }
}

export const actionSelesaiUjianStart = () => {
  return {
    type: actionTypes.SELESAI_UJIAN_START
  }
}

export const actionSelesaiUjianEnd = () => {
  return {
    type: actionTypes.SELESAI_UJIAN_END
  }
}

export const actionSelesaiUjianSuccess = (data) => {
  return {
    type: actionTypes.SELESAI_UJIAN_SUCCESS,
    data,
  }
}

export const actionSelesaiUjianFailed = () => {
  return {
    type: actionTypes.SELESAI_UJIAN_FAILED
  }
}

export const actionKirimJawabanUjian = (data) => {
  return {
    type: actionTypes.KIRIM_JAWABAN_UJIAN,
    data
  }
}

export const actionKirimJawabanUjianSuccess = (data) => {
  return {
    type: actionTypes.KIRIM_JAWABAN_UJIAN_SUCCESS,
    data
  }
}

export const actionResetProsesUjianSuccess = () => {
  return {
    type: actionTypes.RESET_PROSES_UJIAN_SUCCESS,
  }
}