import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import ScrollToTop from '../../ScrollToTop'
import { useIdleTimer } from 'react-idle-timer'
import { connect } from 'react-redux'
import { setExpired, actionRefreshToken } from '../../../store/auth/actions'
import { authExpiredTime, PROD } from '../../../utils/config'
import UseScript from '../../UseSript'

const UjianLayout = (props) => {
    const { children, auth, setExpired } = props

    const [expiredTime, setExpiredTime] = useState(authExpiredTime)

    useEffect(() => {
        if (PROD === true) {
            const disableRighClick = (event) => {
                event.preventDefault()
            }
            document.addEventListener('contextmenu', disableRighClick);
            document.onkeydown = (e) => {
                if (e.keyCode == 123) {
                    return false;
                }
            }

            return () => {
                document.removeEventListener('contextmenu', disableRighClick);
                document.onkeydown = (e) => {
                    if (e.keyCode == 123) {
                        return true;
                    }
                }
            }
        }
    }, [])

    const handleOnIdle = () => {
        addExpired()
    }

    const addExpired = () => {
        let now = parseInt((new Date).getTime() / 1000)
        setExpired(now + authExpiredTime)
    }

    // const handleOnAction = () => {
    //     if (isExpired === false) {
    //         addExpired()
    //     }
    // }

    const { start } = useIdleTimer({
        startManually: true,
        timeout: 1000 * expiredTime,
        onIdle: handleOnIdle,
        // onAction: handleOnAction,
        debounce: 250
    })

    useEffect(() => {
        let now = parseInt((new Date).getTime() / 1000)
        let sisaWaktu = auth?.expiredAt - now
        setExpiredTime(sisaWaktu)

        if (sisaWaktu < 0) {
            addExpired()
        } else {
            start()
        }
    }, [auth?.expiredAt])

    return <React.Fragment>
        <ScrollToTop />

        <div className="layout-wrapper layout-content-navbar  layout-without-menu">
            <div className="layout-container">
                {children}
            </div>

            <div className="layout-overlay layout-menu-toggle"></div>

            <div className="drag-target"></div>
        </div >

        <UseScript src={"/assets/vendor/libs/jquery/jquery.js"} />
        <UseScript src={"/assets/vendor/libs/popper/popper.js"} />
        <UseScript src={"/assets/vendor/js/bootstrap.js"} />
        <UseScript src={"/assets/vendor/libs/perfect-scrollbar/perfect-scrollbar.js"} />

        <UseScript src={"/assets/vendor/libs/bootstrap-datepicker/bootstrap-datepicker.js"} />

        <UseScript src={"/assets/vendor/js/menu.js"} />
        <UseScript src={"/assets/js/main.js"} />
    </React.Fragment >
}

const mapStateToProps = state => {
    const { auth, auth: { user }, ujian: { ujian, prosesUjian } } = state

    return { auth, user, ujian, prosesUjian }
}

export default withRouter(connect(mapStateToProps, { setExpired, actionRefreshToken })(UjianLayout))