import React, { useEffect, useRef, useState } from "react"
import { connect } from "react-redux"
import { Link, withRouter } from "react-router-dom"
import { strtotime } from "../../utils/formatter"
import { changeBreadcrumbs, changeTitle } from "../../utils/page"
import { actionVerifikasiUjian, actionFetchUjian } from '../../store/ujian/actions'
import { alert } from "../../utils/alert"
import { request } from "../../utils/request"

const waktuStatic = 1681102603

const PersiapanUjian = (props) => {
    const { ujian, actionVerifikasiUjian, actionFetchUjian, accessToken, verifikasi, success } = props

    const videoStreamer = useRef(null)
    const videoOverlay = useRef(null)
    const imgWajah = useRef(null)

    const [waktuMenujuUjian, setWaktuMenujuUjian] = useState(null)
    const [statusUjian, setStatusUjian] = useState(null)

    const [verifikasiUjianCalled, setVerifikasiUjianCalled] = useState(false)

    const [selisihWaktu, setSelisihWaktu] = useState(null)
    const [selisihWaktuLoaded, setSelisihWaktuLoaded] = useState(false)
    const [fetchCalled, setFetchCalled] = useState(false)
    const [fetchLoaded, setFetchLoaded] = useState(false)
    const [loadCameraAsset, setLoadCameraAsset] = useState(true)

    useEffect(() => {
        const titlePage = "Persiapan Ujian"
        changeTitle(titlePage)
        changeBreadcrumbs(titlePage)

        actionGetWaktuServer()

        return () => window.location.reload(false);
    }, [])

    const actionGetWaktuServer = async () => {
        setSelisihWaktuLoaded(false)

        // let now = waktuStatic
        let now = parseInt((new Date).getTime() / 1000)
        // console.log(dateTime(now * 1000, "YYYY-MM-DD HH:mm:ss"))
        try {
            const res = await request(process.env.REACT_APP_SERVICE_UJIAN + '/waktu-server', {
                method: 'GET'
            })

            if (res?.success === true) {
                // setSelisihWaktu(waktuStatic + 2 - now)
                setSelisihWaktu(res?.result - now)
                setSelisihWaktuLoaded(true)
            } else {
                setSelisihWaktu(0)
                setSelisihWaktuLoaded(true)
            }
        } catch (error) {
            setSelisihWaktu(0)
            setSelisihWaktuLoaded(true)
        }
    }

    useEffect(() => {
        if (selisihWaktu !== null && fetchLoaded === false) {
            setFetchCalled(true)
            actionFetchUjian({ accessToken, fetchWajah: true })
        }
    }, [selisihWaktu])

    useEffect(() => {
        if (fetchCalled === true) {
            if (success !== null) {
                setFetchLoaded(true)
            }
        }
    }, [success, fetchCalled])

    useEffect(() => {
        // let now = waktuStatic + selisihWaktu
        let now = parseInt((new Date).getTime() / 1000) + selisihWaktu
        let sisaWaktuMenujuUjian = ((strtotime(ujian?.sesiUjian?.waktu_mulai, "YYYY-MM-DD HH:mm:ss") / 1000) - now)

        setWaktuMenujuUjian(sisaWaktuMenujuUjian)
    }, [ujian?.sesiUjian?.waktu_mulai])

    useEffect(() => {
        if (waktuMenujuUjian && ujian?.peserta?.data_wajah) {
            if (waktuMenujuUjian > 0 && waktuMenujuUjian < (60 * 30)) {
                setStatusUjian("Sesi Persiapan")

                Promise.all([
                    window.faceapi.nets.faceRecognitionNet.loadFromUri('/assets/js/face-api/models'),
                    window.faceapi.nets.faceLandmark68Net.loadFromUri('/assets/js/face-api/models'),
                    window.faceapi.nets.ssdMobilenetv1.loadFromUri('/assets/js/face-api/models'),
                    window.faceapi.nets.tinyFaceDetector.loadFromUri('/assets/js/face-api/models')
                ]).then(start)
            } else if (waktuMenujuUjian <= 0) {
                if (ujian?.ujianPeserta?.verifikasi_wajah === 1 || ujian?.ujianPeserta?.verifikasi_wajah === true) {
                    setStatusUjian("Ujian Dimulai")

                    window.location.href = `${process.env.REACT_APP_SUBDIR}/ujian`
                } else {
                    setStatusUjian("Sesi Persiapan")

                    Promise.all([
                        window.faceapi.nets.faceRecognitionNet.loadFromUri('/assets/js/face-api/models'),
                        window.faceapi.nets.faceLandmark68Net.loadFromUri('/assets/js/face-api/models'),
                        window.faceapi.nets.ssdMobilenetv1.loadFromUri('/assets/js/face-api/models'),
                        window.faceapi.nets.tinyFaceDetector.loadFromUri('/assets/js/face-api/models')
                    ]).then(start)
                }
            } else {
                setStatusUjian("Belum Dimulai")
            }
        }
        // setLoaded(true)
    }, [waktuMenujuUjian, ujian?.peserta?.data_wajah])


    const [status, setStatus] = useState("")
    const [faceMatcher, setFaceMatcher] = useState(undefined)

    const start = async () => {
        var content = {
            parent: [JSON.parse(ujian?.peserta?.data_wajah)]
        }
        for (var x = 0; x < Object.keys(content.parent).length; x++) {
            for (var y = 0; y < Object.keys(content.parent[x]._descriptors).length; y++) {
                var results = Object.values(content.parent[x]._descriptors[y])
                content.parent[x]._descriptors[y] = new Float32Array(results)
            }
        }
        let resFaceMatcher = await createFaceMatcher(content);
        setFaceMatcher(resFaceMatcher)
        run();
    }

    const [loadVerifikasiUjian, setLoadVerifikasiUjian] = useState(null)

    const verifikasiUjian = () => {
        setVerifikasiUjianCalled(true)
        actionVerifikasiUjian({ accessToken, token_wajah: ujian?.ujianPeserta?.token_wajah })
    }

    useEffect(() => {
        if (verifikasi?.loading) {
            if (verifikasi?.loading === true) {
                setLoadVerifikasiUjian(true)
            } else {
                setLoadVerifikasiUjian(false)
            }
        }
    }, [verifikasi?.loading])

    useEffect(async () => {
        if (verifikasi?.success && verifikasiUjianCalled === true) {
            if (verifikasi?.success === true) {
                setTimeout(async () => {
                    await alert({ title: "Berhasil verifikasi wajah" })
                    window.location.href = `${process.env.REACT_APP_SUBDIR}/ujian`
                }, 1500)
            }
        }
    }, [verifikasi?.success, verifikasiUjianCalled])

    const getImage = () => {
        let video = videoStreamer.current
        if (video) {
            let canvas = document.createElement('canvas')
            canvas.height = video.videoHeight
            canvas.width = video.videoWidth
            let ctx = canvas.getContext('2d')
            ctx.drawImage(video, 0, 0, canvas.width, canvas.height)
            imgWajah.current.src = canvas.toDataURL()
            imgWajah.current.className = "d-block"
            videoStreamer.current.className = "d-none"
            videoOverlay.current.className = "d-none"
        }
    }

    const shutdownVideoStream = () => {
        let videoEl = videoStreamer.current
        if (videoEl) {
            let stream = videoEl.srcObject
            if (stream) {
                let tracks = stream.getTracks()
                tracks.forEach(function (track) {
                    track.stop()
                })
            }
            videoEl.srcObject = null
        }
    }

    const onPlay = async () => {
        const videoEl = videoStreamer.current

        if (videoEl) {
            if (!videoEl.paused && !videoEl.ended) {
                videoOverlay.current.style.display = 'block'
                const canvas = videoOverlay.current

                if (faceMatcher != undefined) {
                    const displaySize = {
                        width: 1200,
                        height: 800
                    }
                    window.faceapi.matchDimensions(canvas, displaySize)
                    const detections = await window.faceapi.detectAllFaces(videoEl).withFaceLandmarks().withFaceDescriptors()

                    setLoadCameraAsset(false)

                    const resizedDetections = window.faceapi.resizeResults(detections, displaySize)
                    const results = resizedDetections.map(d => faceMatcher.findBestMatch(d.descriptor))
                    if (results?.length > 0) {
                        results.forEach((result, i) => {
                            const box = resizedDetections[i].detection.box
                            const drawBox = new window.faceapi.draw.DrawBox(box)
                            drawBox.draw(canvas)
                            var str = result.toString()
                            let rating = parseFloat(str.substring(str.indexOf('(') + 1, str.indexOf(')')))
                            str = str.substring(0, str.indexOf('('))
                            str = str.substring(0, str.length - 1)
                            if (str == "unknown") {
                                // setStatus("Jimmy menghilang")
                            } else {
                                // rating < 0.7
                                if (rating < 0.5) {
                                    setStatus("Wajah berhasil di verifikasi")
                                    getImage()
                                    shutdownVideoStream()
                                    verifikasiUjian()
                                } else {
                                    // setStatus("Jimmy menghilang")
                                }
                            }
                        })
                    } else {
                        // setStatus("Jimmy menghilang")
                    }
                }
            }
        }

        setTimeout(() => onPlay(), 2000)
    }

    const createFaceMatcher = async (data) => {
        const labeledFaceDescriptors = await Promise.all(data.parent.map(className => {
            const descriptors = [];
            for (var i = 0; i < className._descriptors.length; i++) {
                descriptors.push(className._descriptors[i]);
            }
            return new window.faceapi.LabeledFaceDescriptors(className._label, descriptors);
        }))
        return new window.faceapi.FaceMatcher(labeledFaceDescriptors, 0.6);
    }

    const run = async () => {
        const stream = await navigator.mediaDevices.getUserMedia({
            video: {}
        })
        const videoEl = videoStreamer.current
        if (videoEl) {
            videoEl.srcObject = stream
            setStatus("Kamera dibuka, scan wajah anda.")
        }
    }

    return <React.Fragment>
        <div className="container-xxl flex-grow-1 container-p-y">
            <Link to={process.env.REACT_APP_SUBDIR + "/ujian"} className="btn btn-white mb-2 btn-md-block">Kembali</Link>

            {
                selisihWaktuLoaded === true && fetchLoaded === true ?
                    <div className="card mb-4">
                        <div className="card-body">
                            {
                                statusUjian === "Belum Dimulai" ?
                                    <React.Fragment>
                                        <p>Maaf, sesi persiapan ujian belum dimulai.</p>
                                        <Link to={process.env.REACT_APP_SUBDIR + "/ujian"} className="btn btn-primary btn-sm mb-2" >Kembali</Link>
                                    </React.Fragment>
                                    : null
                            }
                            {
                                statusUjian === "Sesi Persiapan" ?
                                    ujian?.peserta?.data_wajah ?
                                        <React.Fragment>
                                            <div className="auth-scan-cam-wrapper">
                                                <div>
                                                    <div className='box-video'>
                                                        <video ref={videoStreamer} className='video-stream' onLoadedMetadata={() => onPlay()} autoPlay={true}></video>
                                                        <img ref={imgWajah} className="d-none" style={{
                                                            width: "100%", top: 0, left: 0, display: "inline-block", verticalAlign: "baseline", transform: "scaleX(-1)", filter: "opacity(0.85)"
                                                        }} />
                                                        <canvas ref={videoOverlay} className='video-overlay'></canvas>
                                                    </div>
                                                    <p className="text-center mt-3">
                                                        {status}
                                                        {
                                                            loadCameraAsset === true ?
                                                                <span className="spinner-border ms-2" role="status" aria-hidden="true" style={{ borderColor: "rgb(53, 71, 119)", borderRightColor: "rgba(0, 0, 0, 0)", width: "1rem", height: "1rem" }}></span>
                                                                : null
                                                        }
                                                        {
                                                            status === "Wajah berhasil di verifikasi" ?
                                                                loadVerifikasiUjian === true ?
                                                                    <span className="spinner-border ms-2" role="status" aria-hidden="true" style={{ borderColor: "rgb(53, 71, 119)", borderRightColor: "rgba(0, 0, 0, 0)", width: "1rem", height: "1rem" }}></span>
                                                                    : <i className="bx bx-check text-primary" style={{
                                                                        marginBottom: 5,
                                                                        marginLeft: 5
                                                                    }}></i>
                                                                : null
                                                        }
                                                    </p>
                                                </div>
                                            </div>
                                        </React.Fragment>
                                        : null
                                    :
                                    <span className="spinner-border mx-auto d-block" role="status" aria-hidden="true" style={{ borderColor: "rgb(53, 71, 119)", borderRightColor: "rgba(0, 0, 0, 0)", width: "1rem", height: "1rem" }}></span>
                            }
                        </div>
                    </div>
                    :
                    <div className="auth-scan-cam-wrapper">
                        <span className="spinner-border ms-2" role="status" aria-hidden="true"></span>
                    </div>
            }
        </div>
    </React.Fragment>
}

const mapStateToProps = state => {
    const { accessToken } = state.auth
    const { ujian, verifikasi, success } = state.ujian

    return {
        accessToken,
        ujian,
        verifikasi,
        success
    }
}

export default withRouter(connect(mapStateToProps, { actionVerifikasiUjian, actionFetchUjian })(PersiapanUjian))