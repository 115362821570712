import { useEffect } from 'react'

const UseCss = ({ src }) => {
    useEffect(() => {
        const css = document.createElement('link')

        css.setAttribute("rel", "stylesheet")
        css.setAttribute("type", "text/css")
        css.setAttribute("href", src)
        document.head.appendChild(css)

        return () => {
            document.head.removeChild(css)
        }
    }, [src])

    return (null)
}

export default UseCss