import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import { asTimeWithLabel } from "../../utils/formatter"
import { changeBreadcrumbs, changeTitle } from "../../utils/page"
import HeadTable from "../../components/Table/HeadTable"
import { actionFetchUjian } from '../../store/ujian/actions'
import { showHasilUjianJawaban } from "../../utils/config"

const HasilUjian = (props) => {
    const { user, ujian, hasilUjian, actionFetchUjian, accessToken } = props

    useEffect(() => {
        const titlePage = "Hasil Ujian"
        changeTitle(titlePage)
        changeBreadcrumbs(titlePage)

        actionFetchUjian({ accessToken })
    }, [])

    const classStatus = (val) => {
        if (val?.toString() === "1") {
            return "bg-label-primary"
        } else if (val?.toString() === "0") {
            return "bg-label-danger"
        }

        return "bg-label-secondary"
    }

    const valStatus = (val) => {
        if (val?.toString() === "1") {
            return "Benar"
        } else if (val?.toString() === "0") {
            return "Salah"
        }

        return ""
    }

    return <React.Fragment>
        <div className="container-xxl flex-grow-1 container-p-y">
            <div className="card mt-3">
                <div className="card-body">
                    <small className="text-muted text-uppercase">Informasi Peserta</small>
                    <div className="table-responsive detail-view mt-2">
                        <table className="table table-bordered">
                            <tbody className="table-border-bottom-0">
                                <tr>
                                    <th style={{ width: "152px" }}>
                                        <strong>Nama</strong>
                                    </th>
                                    <td>{user?.name || "-"}</td>
                                </tr>
                                <tr>
                                    <th style={{ width: "152px" }}>
                                        <strong>NIK</strong>
                                    </th>
                                    <td>{ujian?.peserta?.nik || "-"}</td>
                                </tr>
                                <tr>
                                    <th style={{ width: "152px" }}>
                                        <strong>Email</strong>
                                    </th>
                                    <td>{user?.email || "-"}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div className="card mt-3">
                {
                    hasilUjian ?
                        <div className="card-body">
                            <small className="text-muted text-uppercase">Hasil Ujian</small>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="table-responsive detail-view mt-2">
                                        <table className="table table-bordered">
                                            <tbody className="table-border-bottom-0">
                                                <tr>
                                                    <th style={{ width: "152px" }}>
                                                        <strong>Waktu Mulai</strong>
                                                    </th>
                                                    <td>{hasilUjian?.waktu_mulai || "-"}</td>
                                                </tr>
                                                <tr>
                                                    <th style={{ width: "152px" }}>
                                                        <strong>Waktu Selesai</strong>
                                                    </th>
                                                    <td>{hasilUjian?.waktu_selesai || "-"}</td>
                                                </tr>
                                                <tr>
                                                    <th style={{ width: "152px" }}>
                                                        <strong>Waktu Ujian</strong>
                                                    </th>
                                                    <td>{asTimeWithLabel(hasilUjian?.waktu_ujian) || "-"}</td>
                                                </tr>
                                                <tr>
                                                    <th style={{ width: "152px" }}>
                                                        <strong>Nilai Akhir</strong>
                                                    </th>
                                                    <td>{hasilUjian?.nilai_akhir || 0}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="table-responsive detail-view mt-2">
                                        <table className="table table-bordered">
                                            <tbody className="table-border-bottom-0">
                                                <tr>
                                                    <th>
                                                        <strong>Jumlah Dikerjakan</strong>
                                                    </th>
                                                    <td>{hasilUjian?.jumlah_dikerjakan || 0}</td>
                                                </tr>
                                                <tr>
                                                    <th>
                                                        <strong>Jumlah Tidak Dikerjakan</strong>
                                                    </th>
                                                    <td>{hasilUjian?.jumlah_tidak_dikerjakan || 0}</td>
                                                </tr>
                                                <tr>
                                                    <th>
                                                        <strong>Jumlah Benar</strong>
                                                    </th>
                                                    <td>{hasilUjian?.jumlah_benar || 0}</td>
                                                </tr>
                                                <tr>
                                                    <th>
                                                        <strong>Jumlah Salah</strong>
                                                    </th>
                                                    <td>{hasilUjian?.jumlah_salah || 0}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>

                            {
                                showHasilUjianJawaban === true ?
                                    <div className="mt-4">
                                        <div className="table-responsive detail-view">
                                            <table className="table table-bordered">
                                                <HeadTable
                                                    colorized={false}
                                                    columns={[
                                                        { type: "label", label: "No." },
                                                        { type: "label", label: "Pertanyaan & Jawaban" },
                                                        { type: "label", label: "Kategori" },
                                                        { type: "label", label: "Hasil" }
                                                    ]} />
                                                <tbody className="table-border-bottom-0">
                                                    {
                                                        hasilUjian?.jawaban?.length > 0 ?
                                                            hasilUjian?.jawaban?.map((val, key) => {
                                                                return <React.Fragment key={key}>
                                                                    <tr>
                                                                        <td rowSpan={2}>{key + 1}</td>
                                                                        <td>
                                                                            <span dangerouslySetInnerHTML={{ __html: val?.soal?.pertanyaan }}></span>
                                                                        </td>
                                                                        <td style={{ width: "200px" }} rowSpan={2}>{val?.soal?.kategori_soal?.nama || "-"}</td>
                                                                        <td style={{ width: "100px" }} rowSpan={2}><span className={"badge me-1 " + classStatus(val?.jawaban_betul)}>{valStatus(val?.jawaban_betul) || "-"}</span></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>{val?.jawaban?.kode ? "[" + val?.jawaban?.kode + "]" : ""} {val?.jawaban?.jawaban || "-"}</td>
                                                                    </tr>
                                                                </React.Fragment>
                                                            })
                                                            :
                                                            <tr>
                                                                <td colSpan={4}>Tidak ada data</td>
                                                            </tr>
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    : null
                            }
                        </div>
                        :
                        <div className="card-body">
                            <small className="text-muted text-uppercase">Hasil Ujian</small>
                            <p className="mb-0 mt-2">Ujian belum dikerjakan</p>
                        </div>
                }
            </div>
        </div>
    </React.Fragment >
}

const mapStateToProps = state => {
    const { user, accessToken } = state.auth
    const { ujian, hasilUjian } = state.ujian

    return { user, accessToken, ujian, hasilUjian }
}

export default withRouter(connect(mapStateToProps, { actionFetchUjian })(HasilUjian))