import React, { useEffect, useRef, useState } from "react"
import { connect } from "react-redux"
import { Link, withRouter } from "react-router-dom"
import IlsLogin from "../assets/img/illustrations/ils-login.png"
import { actionSignIn } from "../store/auth/actions"
import { pushUrl } from "../utils/url"
import TextInput from "../components/Form/TextInput"
import { changeTitle } from "../utils/page"
import { maksScanWajah } from "../utils/config"
import Step from "../components/Form/Step"
import NumberInput from "../components/Form/NumberInput"
import { alert } from "../utils/alert"
import { request } from "../utils/request"
import SvgLogo from "../assets/svg/logo.svg"
import { APP_NAME } from "../constants"
import { dateTime } from "../utils/formatter"

const SignUp = (props) => {
    const [payload, setPayload] = useState({ nama: "", email: "", nik: "", nomor_hp: "" })
    const [errors, setErrors] = useState(null)
    const { success } = props

    const [step, setStep] = useState(1)
    const [maksStep, setMaksStep] = useState(1)
    const [transition, setTransition] = useState(false)

    const [status, setStatus] = useState("")
    const [save, setSave] = useState(false)
    const [counter, setCounter] = useState(maksScanWajah)
    const [descriptions, setDescriptions] = useState([])
    const [fotoWajahArr, setFotoWajahArr] = useState([])
    const [capturing, setCapturing] = useState(null)

    const [loadingSubmitData, setLoadingSubmitData] = useState(false)
    const [loadingSubmitWajah, setLoadingSubmitWajah] = useState(false)

    const videoStreamer = useRef(null)
    const videoOverlay = useRef(null)
    const imgWajah = useRef(null)

    const [loadCameraAsset, setLoadCameraAsset] = useState(true)

    const [waktuPendaftaran, setWaktuPendaftaran] = useState(null)
    const [waktuPendaftaranCalled, setWaktuPendaftaranCalled] = useState(false)
    const [waktuPendaftaranLoading, setWaktuPendaftaranLoading] = useState(false)
    const [pendaftaranDibuka, setPendaftaranDibuka] = useState(null)

    useEffect(() => {
        changeTitle("Daftar")

        getWaktuPendaftaran()
    }, [])

    const getWaktuPendaftaran = async () => {
        setWaktuPendaftaranLoading(true)

        let now = parseInt((new Date).getTime() / 1000)

        try {
            const header = {
                "Content-Type": "application/json",
            }
            const res = await request(process.env.REACT_APP_SERVICE_WAKTU_PENDAFTARAN, {
                method: 'GET',
                headers: header,
            })

            let _pendaftaranDibuka = null

            if (res?.success === true) {
                // setWaktuPendaftaran(res?.result)
                let data = res?.result

                if (data?.waktuServer && data?.waktuTutupPendaftaran) {
                    setWaktuPendaftaran(data)

                    let selisihWaktu = data?.waktuServer - now
                    let realNow = now + selisihWaktu

                    if (realNow < data?.waktuTutupPendaftaran) {
                        _pendaftaranDibuka = true
                    } else {
                        _pendaftaranDibuka = false
                    }
                }
            }

            setPendaftaranDibuka(_pendaftaranDibuka)
            setWaktuPendaftaranCalled(true)
            setWaktuPendaftaranLoading(false)
        }
        catch (error) {
            setWaktuPendaftaranCalled(true)
            setWaktuPendaftaranLoading(false)
        }
    }

    const onSubmitData = async (e) => {
        e.preventDefault()
        setLoadingSubmitData(true)
        setErrors(null)
        if (validate() === true) {
            try {
                const headerPost = {
                    "Content-Type": "application/json",
                }
                const res = await request(process.env.REACT_APP_SERVICE_AUTH + '/validasi', {
                    method: 'POST',
                    headers: headerPost,
                    body: JSON.stringify(payload)
                })

                if (res?.success === true) {
                    setStep(2)
                    setTransition(true)
                } else {
                    setErrors(res?.errors)

                    if (res?.message === "Gagal, waktu pendaftaran telah ditutup")
                        alert({ title: "Gagal", html: res?.message })
                }
            }
            catch (error) {
                alert({ title: "Gagal", html: "Gagal daftar, terjadi kesalahan di sisi server." })
            }
        }

        setLoadingSubmitData(false)
    }

    useEffect(() => {
        if (step > maksStep) {
            setMaksStep(step)
        }

        if (step === 2) {
            Promise.all([
                window.faceapi.nets.faceRecognitionNet.loadFromUri('/assets/js/face-api/models'),
                window.faceapi.nets.faceLandmark68Net.loadFromUri('/assets/js/face-api/models'),
                window.faceapi.nets.ssdMobilenetv1.loadFromUri('/assets/js/face-api/models'),
                window.faceapi.nets.tinyFaceDetector.loadFromUri('/assets/js/face-api/models')
            ]).then(start)
        }
    }, [step])

    useEffect(() => {
        if (transition === true) {
            setTimeout(() => setTransition(false), 800)
        }
    }, [transition])

    const shutdownVideoStream = () => {
        const videoEl = videoStreamer.current

        if (videoEl) {
            let stream = videoEl.srcObject
            let tracks = stream.getTracks()
            tracks.forEach(function (track) {
                track.stop()
            })
            videoEl.srcObject = null
        }
    }

    const onBack = () => {
        if (step < 2) {
            pushUrl("/sign-in")
        } else {
            shutdownVideoStream()
            setTransition(true)
            setStep(1)
        }
    }

    const validate = () => {
        let jsonErrors = {}
        let hasError = false

        if (!payload?.nama || payload?.nama === "") {
            hasError = true
            jsonErrors["nama"] = "Nama harus diisi"
        }
        if (!payload?.nik || payload?.nik === "") {
            hasError = true
            jsonErrors["nik"] = "NIK harus diisi"
        }
        if (!payload?.email || payload?.email === "") {
            hasError = true
            jsonErrors["email"] = "Email harus diisi"
        }
        if (!payload?.nomor_hp || payload?.nomor_hp === "") {
            hasError = true
            jsonErrors["nomor_hp"] = "5 Digit Belakang Nomor HP harus diisi"
        }

        setErrors(jsonErrors)

        if (hasError === true) {
            return false
        } else {
            return true
        }
    }

    const onChange = ((field, value) => {
        if (field === "nik") {
            payload[field] = value?.slice(0, 16)
        } else if (field === "nomor_hp") {
            payload[field] = value?.slice(0, 5)
        } else if (field === "email") {
            payload[field] = value?.toLowerCase()
        } else {
            payload[field] = value
        }

        setPayload({
            ...payload,
        })
    })

    useEffect(() => {
        if (success === true) {
            window.location.href = `${process.env.REACT_APP_SUBDIR}/beranda`
        }
    }, [success])

    const start = async () => {
        run()
    }

    const run = async () => {
        const stream = await navigator.mediaDevices.getUserMedia({
            video: {}
        })
        const videoEl = videoStreamer.current
        if (videoEl) {
            videoEl.srcObject = stream
            setStatus("Kamera dibuka, scan wajah anda.")
        }
    }

    let inputSize = 160
    let scoreThreshold = 0.4

    const getFaceDetectorOptions = async () => {
        return new window.faceapi.TinyFaceDetectorOptions({
            inputSize,
            scoreThreshold
        })
    }

    const onPlay = async () => {
        const videoEl = videoStreamer.current

        if (videoEl) {
            if (!videoEl?.paused && !videoEl?.ended) {
                videoOverlay.current.style.display = 'block'
                const canvas = videoOverlay.current

                const options = getFaceDetectorOptions()

                setLoadCameraAsset(false)

                options?.then(async (resOptions) => {
                    const result = await window.faceapi.detectSingleFace(videoEl, resOptions)
                    if (result) {
                        const dims = window.faceapi.matchDimensions(canvas, videoEl, true)
                        dims.height = 800
                        dims.width = 1200
                        canvas.height = 800
                        canvas.width = 1200
                        const resizedResult = window.faceapi.resizeResults(result, dims)
                        window.faceapi.draw.drawDetections(canvas, resizedResult)
                    } else {
                        videoOverlay.current.style.display = 'none'
                    }
                })
            }
        }

        setTimeout(() => onPlay(), 2000)
    }

    const capture = () => {
        if (counter <= maksScanWajah && counter >= 0) {
            setCapturing(true)
        } else {
            setCapturing(false)
            setStatus("Limit")
        }
    }

    useEffect(() => {
        const videoEl = videoStreamer.current
        if (capturing === true) {
            setStatus("Data wajah sedang diproses")
            if (videoEl) {
                videoEl?.pause()
            }
        } else if (capturing === false) {
            if (videoEl) {
                videoEl?.play()
            }
        }

        let act = setTimeout(() => {
            if (capturing === true) {
                doCapture()
            }
        }, 300)

        return () => {
            clearTimeout(act)
        }
    }, [capturing])

    useEffect(() => {
        const videoEl = videoStreamer.current
        if (counter === 0) {
            if (videoEl) {
                videoEl?.pause()
            }
        }
    }, [counter])

    const doCapture = async () => {
        let video = videoStreamer.current
        if (video) {
            let canvas = document.createElement('canvas')
            // canvas.height = video.videoHeight
            // canvas.width = video.videoWidth
            canvas.height = 256
            canvas.width = 320
            // canvas.height = 192
            // canvas.width = 256
            let ctx = canvas.getContext('2d')
            ctx.drawImage(video, 0, 0, canvas.width, canvas.height)
            let imgUrl = canvas.toDataURL()
            let img = document.createElement('img')
            img.src = imgUrl

            const detections = await window.faceapi.detectSingleFace(img).withFaceLandmarks().withFaceDescriptor()
            if (detections != null) {
                fotoWajahArr.push(imgUrl)
                setFotoWajahArr(fotoWajahArr)

                let newDes = descriptions
                newDes.push(detections.descriptor)
                setDescriptions(newDes)
                if ((counter - 1) < 1) {
                    imgWajah.current.src = imgUrl
                    shutdownVideoStream()
                    imgWajah.current.className = "d-block"
                    setStatus("Data wajah telah lengkap")
                }
                setCounter(counter - 1)
                if ((counter - 1) > 0) {
                    setStatus("Sisa scan: " + (counter - 1))
                }
                setCapturing(false)
            } else {
                setCapturing(false)
                setStatus("Wajah tidak terdeteksi, gambar kurang jelas. Perhatikan pencahayaan sekitar, atau disarankan menggunakan kamera lain.")
            }
        } else {
            setStatus("Terjadi kesalahan, harap refresh halaman")
        }
    }

    const onSubmitWajah = async (e) => {
        e.preventDefault()
        setLoadingSubmitWajah(true)

        if (counter === 0) {
            try {
                const headerPost = {
                    "Content-Type": "application/json",
                }
                const data_wajah = await new window.faceapi.LabeledFaceDescriptors(payload?.nik, descriptions)
                payload['data_wajah'] = data_wajah

                // payload['foto_wajah'] = fotoWajah?.replace("data:image/png;base64,", "")

                let _fotoWajahArr = fotoWajahArr
                _fotoWajahArr?.map((val, key) => {
                    _fotoWajahArr[key] = val?.replace("data:image/png;base64,", "")
                })
                payload['foto_wajah'] = _fotoWajahArr

                const res = await request(process.env.REACT_APP_SERVICE_AUTH + '/register', {
                    method: 'POST',
                    headers: headerPost,
                    body: JSON.stringify(payload)
                })

                if (res?.success === true) {
                    setSave(true)

                    setStep(3)
                    setTransition(true)
                } else {
                    setErrors(res?.errors)

                    if (res?.message === "Gagal, waktu pendaftaran telah ditutup")
                        alert({ title: "Gagal", html: res?.message })
                }
            } catch (error) {
                alert({ title: "Gagal", html: "Gagal daftar, terjadi kesalahan di sisi server." })
            }
        }

        setLoadingSubmitWajah(false)
    }

    const viewPendaftaran = () => {
        return <React.Fragment>
            <div className="bs-stepper shadow-none linear">
                <Step items={[
                    { label: "Data", detail: "Informasi pengguna", icon: "bx bx-list-check", active: step === 1, disabled: step === 3 || maksStep < 1 },
                    { label: "Wajah", detail: "Identifikasi wajah", icon: "bx bxs-user-rectangle", active: step === 2, disabled: step === 3 || maksStep < 2 },
                    { label: "Berhasil", detail: "Pendaftaran selesai", icon: "bx bx-check", active: step === 3, disabled: maksStep < 3 }
                ]} />

                {
                    step === 1 ?
                        <div className="bs-stepper-content">
                            <h4 className="mb-2">Halaman Daftar</h4>
                            <p className="mb-4">Isi data berikut untuk mendaftarkan akun anda</p>

                            <form onSubmit={onSubmitData} className="fv-plugins-bootstrap5">
                                <div className="mb-3">
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <TextInput
                                                id={"nama"}
                                                name={"nama"}
                                                placeholder={"Masukkan nama anda"}
                                                label={"Nama"}
                                                onChange={(e) => onChange('nama', e?.target?.value)}
                                                required={true}
                                                value={payload?.nama}
                                                error={errors?.nama}
                                                tabIndex={1}
                                            />
                                        </div>
                                        <div className="col-lg-6">
                                            <NumberInput
                                                id={"nik"}
                                                name={"nik"}
                                                placeholder={"Masukkan NIK anda"}
                                                label={"NIK"}
                                                onChange={(e) => onChange('nik', e?.target?.value)}
                                                required={true}
                                                value={payload?.nik}
                                                error={errors?.nik}
                                                tabIndex={2}
                                            />
                                        </div>
                                        <div className="col-lg-6">
                                            <TextInput
                                                id={"email"}
                                                name={"email"}
                                                placeholder={"Masukkan email anda"}
                                                label={"Email"}
                                                onChange={(e) => onChange('email', e?.target?.value)}
                                                required={true}
                                                value={payload?.email}
                                                error={errors?.email}
                                                tabIndex={3}
                                            />
                                        </div>
                                        <div className="col-lg-6">
                                            <NumberInput
                                                id={"nomor_hp"}
                                                name={"nomor_hp"}
                                                placeholder={"Masukkan 5 digit belakang nomor HP"}
                                                label={"5 Digit Belakang Nomor HP"}
                                                onChange={(e) => onChange('nomor_hp', e?.target?.value)}
                                                required={true}
                                                value={payload?.nomor_hp}
                                                error={errors?.nomor_hp}
                                                tabIndex={4}
                                            />
                                        </div>
                                    </div>

                                    <div className="col-12 d-flex justify-content-between mt-4">
                                        <button type="button" onClick={onBack} className="btn btn-label-secondary btn-prev">
                                            Kembali
                                        </button>
                                        <button tabIndex={5} type="submit" className="btn btn-primary btn-next" disabled={loadingSubmitData === true}>
                                            Lanjutkan
                                            {loadingSubmitData === true ? <span className="spinner-border ms-2" role="status" aria-hidden="true"></span> : null}
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                        : null
                }

                {
                    step === 2 ?
                        <div className="bs-stepper-content">
                            <h4 className="mb-2">Halaman Indentifikasi Wajah</h4>
                            <p className="mb-4">Lengkapi data wajah untuk mendaftarkan akun anda</p>

                            <form onSubmit={onSubmitWajah} className="fv-plugins-bootstrap5">
                                <div>
                                    <div className='box-video'>
                                        {
                                            counter > 0 ? <React.Fragment>
                                                <video ref={videoStreamer} className='video-stream' onLoadedMetadata={() => onPlay()} autoPlay={true}></video>
                                                <canvas ref={videoOverlay} className='video-overlay'></canvas>
                                            </React.Fragment> :
                                                null
                                        }
                                        <img ref={imgWajah} className="d-none" style={{
                                            width: "100%", top: 0, left: 0, display: "inline-block", verticalAlign: "baseline", transform: "scaleX(-1)", filter: "opacity(0.85)"
                                        }} />
                                    </div>
                                    <p className="text-center mt-3">
                                        {status}
                                        {
                                            loadCameraAsset === true ?
                                                <span className="spinner-border ms-2" role="status" aria-hidden="true" style={{ borderColor: "rgb(53, 71, 119)", borderRightColor: "rgba(0, 0, 0, 0)", width: "1rem", height: "1rem" }}></span>
                                                : null
                                        }
                                    </p>
                                </div>

                                <div className="row mt-3">
                                    <div className="col-12 d-flex justify-content-between">
                                        <button type="button" onClick={onBack} className="btn btn-label-secondary btn-prev">
                                            Kembali
                                        </button>

                                        {
                                            counter > 0 && status !== "" ?
                                                <button onClick={capture} type="button" className="btn btn-primary btn-next" disabled={capturing === true}>
                                                    Scan
                                                    {capturing === true ? <span className="spinner-border ms-2" role="status" aria-hidden="true"></span> : null}
                                                </button>
                                                : null
                                        }

                                        {
                                            counter === 0 && save === false ?
                                                <button type="submit" className="btn btn-primary btn-next" disabled={loadingSubmitWajah === true}>
                                                    Lanjutkan
                                                    {loadingSubmitWajah === true ? <span className="spinner-border ms-2" role="status" aria-hidden="true"></span> : null}
                                                </button>
                                                : null
                                        }
                                    </div>
                                </div>
                            </form>
                        </div>
                        : null
                }

                {
                    step === 3 ?
                        <div className="bs-stepper-content">
                            <h4 className="mb-2">Pendaftaran Berhasil</h4>
                            <p className="mb-4">Periksa kotak masuk email anda untuk mendapatkan username dan password akun P3TGAI.</p>

                            <div className="row">
                                <div className="col-12 d-flex justify-content-between">
                                    <Link to={process.env.REACT_APP_SUBDIR + "/sign-in"} className="btn btn-primary" disabled={loadingSubmitData === true || loadingSubmitWajah === true}>
                                        Masuk ke akun anda
                                        {loadingSubmitData === true || loadingSubmitWajah === true ? <span className="spinner-border ms-2" role="status" aria-hidden="true"></span> : null}
                                    </Link>
                                </div>
                            </div>
                        </div>
                        : null
                }
            </div>
        </React.Fragment>
    }

    const viewPendaftaranDitutup = () => {
        return <React.Fragment>
            <div className="app-brand mb-3">
                <Link to={process.env.REACT_APP_SUBDIR + "/"} className="app-brand-link gap-2">
                    <span className="app-brand-logo demo">
                        <img src={SvgLogo} alt="Logo" className="logo-app" />
                    </span>
                    <span className="app-brand-text demo text-body fw-bolder main-logo-text text-primary">{APP_NAME}</span>
                </Link>
            </div>
            <h4 className="mb-4">Halaman Peserta Seleksi</h4>

            <p className="mb-0 text-danger fw-bold">Pendaftaran Ditutup</p>
            <p>Batas akhir pendaftaran {dateTime(waktuPendaftaran?.waktuTutupPendaftaran * 1000, "DD-MMM-YYYY HH:mm")}</p>

            <div className="col-12 d-flex justify-content-between mt-4">
                <button type="button" onClick={onBack} className="btn btn-label-secondary btn-prev">
                    Kembali
                </button>
            </div>
        </React.Fragment>
    }

    const viewGagalPanggilWaktuPendaftaran = () => {
        return <React.Fragment>
            <div className="app-brand mb-3">
                <Link to={process.env.REACT_APP_SUBDIR + "/"} className="app-brand-link gap-2">
                    <span className="app-brand-logo demo">
                        <img src={SvgLogo} alt="Logo" className="logo-app" />
                    </span>
                    <span className="app-brand-text demo text-body fw-bolder main-logo-text text-primary">{APP_NAME}</span>
                </Link>
            </div>
            <h4 className="mb-4">Halaman Peserta Seleksi</h4>

            <p className="mb-0">Gagal meminta data ke server, disarankan untuk refresh ulang halaman.</p>
            <p>Hubungi petugas jika masih gagal.</p>

            <div className="col-12 d-flex justify-content-between mt-4">
                <button type="button" onClick={onBack} className="btn btn-label-secondary btn-prev">
                    Kembali
                </button>
            </div>
        </React.Fragment>
    }

    return <React.Fragment>
        <div className="authentication-inner row m-0">
            <div className={"d-none d-lg-flex align-items-center justify-content-end pe-0 transition-width " + (step !== 2 ? "col-lg-4 p-5" : "width-none p-0")}>
                <div className="w-100 d-flex justify-content-center">
                    {
                        transition !== true ?
                            <img src={IlsLogin} className="img-fluid" alt="Login" style={{ maxHeight: "80vh", width: "auto" }} />
                            : null
                    }
                </div>
            </div>

            <div className={"d-flex align-items-center justify-content-center authentication-bg p-sm-5 p-3 transition-width " + (step !== 2 ? "col-lg-8" : "col-lg-12")}>
                <div className="w-px-700 mx-auto">
                    {
                        waktuPendaftaranLoading === true ?
                            <span className="spinner-border mx-auto d-block" role="status" aria-hidden="true" style={{ borderColor: "rgb(53, 71, 119)", borderRightColor: "rgba(0, 0, 0, 0)", width: "1rem", height: "1rem" }}></span>
                            : null
                    }
                    {
                        waktuPendaftaranCalled === true && pendaftaranDibuka === true ?
                            viewPendaftaran()
                            : null
                    }
                    {
                        waktuPendaftaranCalled === true && pendaftaranDibuka === false ?
                            viewPendaftaranDitutup()
                            : null
                    }
                    {
                        waktuPendaftaranCalled === true && pendaftaranDibuka === null ?
                            viewGagalPanggilWaktuPendaftaran()
                            : null
                    }
                </div>
            </div>
        </div >
    </React.Fragment >
}

const mapStateToProps = state => {
    const { loading, success } = state.auth
    return { loading, success }
}

export default withRouter(connect(mapStateToProps, { actionSignIn })(SignUp))