import Immutable from 'seamless-immutable'
import actionTypes from './actionTypes'
import { default as actionTypesAuth } from './../auth/actionTypes'

let prosesUjian = {
  nomor: null,
  jawaban: null,
  loading: false,
  success: null
}

let verifikasi = {
  loading: false,
  success: null
}

let proses = {
  loading: false,
  success: null
}

let selesai = {
  loading: false,
  success: null
}

let model = {
  ujian: null,
  prosesUjian,
  verifikasi,
  hasilUjian: null,
  proses,
  selesai,
  loading: false,
  success: null
}
export const INITIAL_STATE = Immutable(model)

function reducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case actionTypes.VERIFIKASI_UJIAN_START:
      return {
        ...state,
        verifikasi: {
          loading: true,
          success: null
        }
      }
    case actionTypes.VERIFIKASI_UJIAN_END:
      return {
        ...state,
        verifikasi: {
          ...state?.verifikasi,
          loading: false,
        }
      }
    case actionTypes.VERIFIKASI_UJIAN_SUCCESS:
      return {
        ...state,
        verifikasi: {
          ...state?.verifikasi,
          success: true
        },
        ujian: {
          ...state?.ujian,
          ujianPeserta: {
            ...state?.ujian?.ujianPeserta,
            verifikasi_wajah: 1
          }
        },
        prosesUjian: {
          ...state?.prosesUjian,
          nomor: 1,
          jawaban: action?.data
        },
      }
    case actionTypes.VERIFIKASI_UJIAN_FAILED:
      return {
        ...state,
        verifikasi: {
          ...state?.verifikasi,
          success: false
        },
        ujian: {
          ...state?.ujian,
          ujianPeserta: {
            ...state?.ujian?.ujianPeserta,
            verifikasi_wajah: 0
          }
        },
        prosesUjian: {
          ...state?.prosesUjian,
          nomor: null,
          jawaban: action?.data
        },
      }

    case actionTypes.RESET_PROSES_UJIAN_SUCCESS:
      return {
        ...state,
        proses: {
          loading: null,
          success: null
        }
      }
    case actionTypes.PROSES_UJIAN_START:
      return {
        ...state,
        proses: {
          loading: true,
          success: null
        }
      }
    case actionTypes.PROSES_UJIAN_END:
      return {
        ...state,
        proses: {
          ...state?.proses,
          loading: false,
        }
      }
    case actionTypes.PROSES_UJIAN_SUCCESS:
      return {
        ...state,
        proses: {
          ...state?.proses,
          success: true
        },
        ujian: {
          ...state?.ujian,
          ujianPeserta: {
            ...state?.ujian?.ujianPeserta,
            waktu_mulai: action?.data?.waktu_mulai,
          }
        },
        prosesUjian: {
          ...state?.prosesUjian,
          jawaban: action?.data?.jawaban
        },
      }
    case actionTypes.PROSES_UJIAN_FAILED:
      return {
        ...state,
        proses: {
          ...state?.proses,
          success: false
        },
        ujian: {
          ...state?.ujian,
          ujianPeserta: {
            ...state?.ujian?.ujianPeserta,
            waktu_mulai: null,
          }
        },
      }

    case actionTypes.SELESAI_UJIAN_START:
      return {
        ...state,
        selesai: {
          loading: true,
          success: null
        }
      }
    case actionTypes.SELESAI_UJIAN_END:
      return {
        ...state,
        selesai: {
          ...state?.selesai,
          loading: false,
        }
      }
    case actionTypes.SELESAI_UJIAN_SUCCESS:
      return {
        ...state,
        selesai: {
          ...state?.selesai,
          success: true
        },
        ujian: {
          ...state?.ujian,
          ujianPeserta: {
            ...state?.ujian?.ujianPeserta,
            waktu_selesai: action?.data?.waktu_selesai,
          }
        },
        hasilUjian: action?.data?.hasilUjian
      }
    case actionTypes.SELESAI_UJIAN_FAILED:
      return {
        ...state,
        selesai: {
          ...state?.selesai,
          success: false
        },
        ujian: {
          ...state?.ujian,
          ujianPeserta: {
            ...state?.ujian?.ujianPeserta,
            waktu_selesai: null,
          }
        },
      }

    case actionTypes.KIRIM_JAWABAN_UJIAN_SUCCESS:
      return {
        ...state,
        prosesUjian: {
          ...state?.prosesUjian,
          jawaban: action?.data
        }
      }

    case actionTypes.FETCH_UJIAN_START:
      return {
        ...state,
        loading: true,
        success: null
      }
    case actionTypes.FETCH_UJIAN_END:
      return { ...state, loading: false }
    case actionTypes.FETCH_UJIAN_SUCCESS:
      return {
        ...state,
        success: true,
        ujian: action.data?.ujian,
        hasilUjian: action?.data?.hasilUjian
      }

    case actionTypesAuth.LOGOUT:
      return { ...model }
    default:
      return state
  }
}

export default reducer
