import { combineReducers } from "redux"
import { connectRouter } from 'connected-react-router'
import history from '../utils/history'

import auth from "./auth/reducer"
import ujian from "./ujian/reducer"

const rootReducer = combineReducers({
  router: connectRouter(history),
  auth,
  ujian
})

export default rootReducer
