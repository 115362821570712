import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import { asTimeWithLabel, strtotime } from "../../utils/formatter"
import { alert, confirm } from "../../utils/alert"
import { changeTitle } from "../../utils/page"
import { actionProsesUjian, actionSelesaiUjian, actionKirimJawabanUjian, actionResetProsesUjianSuccess } from '../../store/ujian/actions'
import { request } from "../../utils/request"

const colPerRow = 6

const waktuStatic = 1681102603

const ProsesUjian = (props) => {
    const { accessToken, ujian, prosesUjian, actionProsesUjian, actionSelesaiUjian, actionKirimJawabanUjian, hasilUjian, selesai, proses, actionResetProsesUjianSuccess } = props

    const [error, setError] = useState(null)
    const [soal, setSoal] = useState(null)
    const [jawaban, setJawaban] = useState(null)
    const [jawabanSebelumnya, setJawabanSebelumnya] = useState(null)
    const [opsiJawaban, setOpsiJawaban] = useState(null)
    const [menuShow, setMenuShow] = useState(false)
    const [terisiSemua, setTerisiSemua] = useState(false)
    const [nomorSekarang, setNomorSekarang] = useState(null)
    const [arrNomorJawaban, setArrNomorJawaban] = useState(null)
    const [arrJawabanDiisi, setArrJawabanDiisi] = useState(null)
    const [waktuUjian, setWaktuUjian] = useState(null)
    const [selesaiSubmit, setSelesaiSubmit] = useState(false)

    const [selisihWaktu, setSelisihWaktu] = useState(null)
    const [selisihWaktuLoaded, setSelisihWaktuLoaded] = useState(false)
    const [fetchLoaded, setFetchLoaded] = useState(false)
    const [prosesUjianCalled, setProsesUjianCalled] = useState(false)

    useEffect(async () => {
        let titlePage = "Proses Ujian"
        changeTitle(titlePage)

        actionResetProsesUjianSuccess()
        await actionGetWaktuServer()

        window.addEventListener("resize", checkSize)

        return () => window.removeEventListener("resize", checkSize)
    }, [])

    const checkSize = () => {
        if (window.innerWidth > window.innerHeight) {
            if (menuShow === true) {
                setMenuShow(false)
            }
        }
    }

    useEffect(() => {
        window.addEventListener("resize", checkSize)

        return () => window.removeEventListener("resize", checkSize)
    }, [menuShow])


    const actionGetWaktuServer = async () => {
        setSelisihWaktuLoaded(false)

        // let now = waktuStatic
        let now = parseInt((new Date).getTime() / 1000)
        // console.log(dateTime(now * 1000, "YYYY-MM-DD HH:mm:ss"))
        try {
            const res = await request(process.env.REACT_APP_SERVICE_UJIAN + '/waktu-server', {
                method: 'GET'
            })

            if (res?.success === true) {
                // setSelisihWaktu(waktuStatic + 2 - now)
                setSelisihWaktu(res?.result - now)
                setSelisihWaktuLoaded(true)
            } else {
                setSelisihWaktu(0)
                setSelisihWaktuLoaded(true)
            }
        } catch (error) {
            setSelisihWaktu(0)
            setSelisihWaktuLoaded(true)
        }
    }

    useEffect(() => {
        if (selisihWaktuLoaded === true && prosesUjianCalled === false) {
            setProsesUjianCalled(true)
            actionProsesUjian({ accessToken, jawaban: prosesUjian?.jawaban })
        }
    }, [selisihWaktuLoaded])

    useEffect(() => {
        if (prosesUjianCalled === true) {
            if (proses?.success !== null) {
                setFetchLoaded(true)
            }
        }
    }, [proses?.success, prosesUjianCalled])

    useEffect(() => {
        if (fetchLoaded === true) {
            setArrNomorSoal()
        }
    }, [prosesUjian?.jawaban, fetchLoaded])

    useEffect(() => {
        setError(null)
        if (nomorSekarang !== null && fetchLoaded === true) {
            setPertanyaanJawaban()
        }
    }, [nomorSekarang, fetchLoaded])

    useEffect(() => {
        if (ujian?.sesiUjian?.waktu_selesai && fetchLoaded === true) {
            // let now = waktuStatic + selisihWaktu
            let now = parseInt((new Date).getTime() / 1000) + selisihWaktu
            let sisaWaktuUjian = ((strtotime(ujian?.sesiUjian?.waktu_selesai, "YYYY-MM-DD HH:mm:ss") / 1000) - now)

            setWaktuUjian(sisaWaktuUjian)
        }
    }, [ujian?.sesiUjian?.waktu_selesai, fetchLoaded])

    useEffect(async () => {
        if (waktuUjian === null) return
        if (!waktuUjian) return
        if (waktuUjian < 0) {
            if (selesaiSubmit === false) {
                setSelesaiSubmit(true)
                actionSelesaiUjian({ accessToken, jawaban: prosesUjian?.jawaban })
            }
            return
        }

        const intervalId = setInterval(() => {
            // let now = waktuStatic + selisihWaktu
            let now = parseInt((new Date).getTime() / 1000) + selisihWaktu
            let sisaUjian = ((strtotime(ujian?.sesiUjian?.waktu_selesai, "YYYY-MM-DD HH:mm:ss") / 1000) - now)

            setWaktuUjian(sisaUjian)
        }, 1000)

        return () => clearInterval(intervalId)
    }, [waktuUjian])

    const pilihJawaban = (jawabanId) => {
        setJawaban(jawabanId)
    }

    const setPertanyaanJawaban = () => {
        let nomor = nomorSekarang
        let jawabanSekarang = prosesUjian?.jawaban[nomor - 1]

        let soal = null
        let opsiJawaban = null
        let jawaban = null
        let jawabanSebelumnya = null

        if (jawabanSekarang) {
            soal = jawabanSekarang?.soal
            opsiJawaban = jawabanSekarang?.soal?.jawaban
            jawaban = jawabanSekarang?.jawaban_id
            jawabanSebelumnya = jawabanSekarang?.jawaban_id
        }

        if (soal?.pertanyaan) {
            if (!soal?.pertanyaan.includes('<') && !soal?.pertanyaan.includes('>') && !soal?.pertanyaan.includes('</')) {
                soal['pertanyaan'] = `<p>${soal?.pertanyaan}</p>`
            }
        }

        setSoal(soal)
        setOpsiJawaban(opsiJawaban)
        setJawaban(jawaban)
        setJawabanSebelumnya(jawabanSebelumnya)
    }

    const clickSelesai = async () => {
        let res = await confirm({ title: "Selesai", html: "Apakah anda yakin mengakhiri ujian?" })

        if (res === true) {
            setSelesaiSubmit(true)
            actionSelesaiUjian({ accessToken, jawaban: prosesUjian?.jawaban })
        }
    }

    useEffect(async () => {
        if (fetchLoaded === true && hasilUjian?.status) {
            if (hasilUjian?.status === "Ujian Selesai") {
                await alert({ title: "Selesai", html: "Ujian telah berakhir" })
                window.location.href = `${process.env.REACT_APP_SUBDIR}/hasil-ujian`
                return
            }
        }
    }, [hasilUjian?.status, fetchLoaded])

    const viewFooter = () => {
        return terisiSemua === true ? null : <React.Fragment>
            <div className="row">
                <div className="col">
                    <button className={"btn btn-white btn-block " + (nomorSekarang <= 1 ? "disabled" : "")} type="button" onClick={() => clickNomorSebelumnya()} tabIndex={8}>Kembali</button>
                </div>
                <div className="col">
                    <button className="btn btn-primary btn-block" type="button" onClick={() => clickSimpanJawaban()} tabIndex={6}>Simpan</button>
                </div>
                <div className="col">
                    <button className={"btn btn-white btn-block " + (nomorSekarang >= prosesUjian?.jawaban?.length ? "disabled" : "")} type="button" onClick={() => clickNomorSelanjutnya()} tabIndex={7}>Lanjut</button>
                </div>
            </div>
        </React.Fragment>
    }

    const onClickMenu = (e) => {
        e.preventDefault()
        setMenuShow(!menuShow)
    }

    const viewMenuNomor = () => {
        return arrNomorJawaban?.length > 0 ? < React.Fragment >
            <div className="card mb-3">
                <table className="table table-bordered text-center grid-ujian border-grid-ujian">
                    <tbody>
                        {
                            arrNomorJawaban?.map((x, keyX) => {
                                return <React.Fragment key={keyX}>
                                    <tr>
                                        {
                                            x?.map((y, keyY) => {
                                                return <React.Fragment key={keyY}>
                                                    <td style={{ borderLeft: 0 }}>
                                                        {
                                                            (nomorSekarang - 1) === y ?
                                                                <button type="button" className="btn badge badge-center bg-label-primary">{y + 1}</button>
                                                                :
                                                                (arrJawabanDiisi).includes(y) ?
                                                                    <button onClick={() => changeNomorSekarang(y + 1)} type="button" className="btn badge badge-center bg-label-success">{y + 1}</button>
                                                                    :
                                                                    <button onClick={() => changeNomorSekarang(y + 1)} type="button" className="btn badge badge-center bg-label-secondary">{y + 1}</button>
                                                        }
                                                    </td>
                                                </React.Fragment>
                                            })
                                        }
                                    </tr>
                                </React.Fragment>
                            })
                        }
                    </tbody>
                </table>
            </div>
            <div className="card mb-4">
                <table className="table table-bordered border-grid-ujian">
                    <tbody>
                        <tr>
                            <th style={{ borderLeft: 0 }}>Jumlah Dikerjakan</th>
                            <td style={{ borderRight: 0 }} className="text-end">{arrJawabanDiisi?.length || 0}</td>
                        </tr>
                        <tr>
                            <th style={{ borderLeft: 0 }}>Jumlah Belum Dikerjakan</th>
                            <td style={{ borderRight: 0 }} className="text-end">{prosesUjian?.jawaban?.length && arrJawabanDiisi?.length ? prosesUjian?.jawaban?.length - arrJawabanDiisi?.length : 0}</td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <button onClick={clickSelesai} className="btn btn-primary btn-block" type="button" disabled={selesaiSubmit === true && selesai?.loading === true}>
                Selesai
                {selesaiSubmit === true && selesai?.loading === true ? <span className="spinner-border ms-2" role="status" aria-hidden="true"></span> : null}
            </button>
        </React.Fragment > : null
    }

    const changeNomorSekarang = (val) => {
        setTerisiSemua(false)
        setNomorSekarang(val)

        if (menuShow === true) {
            setMenuShow(false)
        }
    }

    const clickNomorSebelumnya = () => {
        setNomorSekarang(nomorSekarang - 1)
    }

    const clickNomorSelanjutnya = () => {
        setNomorSekarang(nomorSekarang + 1)
    }

    const cekNomorBelumDikerjakan = (nomorSekarang) => {
        let listJawabaan = prosesUjian?.jawaban
        let jumlahJawaban = listJawabaan?.length

        for (let x = 0; x < jumlahJawaban && x !== (nomorSekarang - 1); x++) {
            if (!arrJawabanDiisi.includes(x)) {
                return x + 1
            }
        }

        return null
    }

    const clickSimpanJawaban = () => {
        if (jawaban) {
            if (jawaban !== jawabanSebelumnya) {
                actionKirimJawabanUjian({ accessToken, jawaban: prosesUjian?.jawaban, soal_id: soal?.id, jawaban_id: jawaban })
            }

            if (nomorSekarang < prosesUjian?.jawaban?.length) {
                setNomorSekarang(nomorSekarang + 1)
            } else {
                let nomorBelumDikerjakan = cekNomorBelumDikerjakan(nomorSekarang)
                if (nomorBelumDikerjakan) {
                    setNomorSekarang(nomorBelumDikerjakan)
                } else {
                    setNomorSekarang(null)
                    setTerisiSemua(true)
                }
            }

            let _arrJawabanDiisi = arrJawabanDiisi
            if (!_arrJawabanDiisi.includes(nomorSekarang - 1)) {
                _arrJawabanDiisi.push(nomorSekarang - 1)
            }

            setArrJawabanDiisi(_arrJawabanDiisi)
        } else {
            setError("Jawaban belum diisi")
        }
    }

    const setArrNomorSoal = () => {
        setNomorSekarang(1)

        let listJawabaan = prosesUjian?.jawaban
        let jumlahJawaban = listJawabaan?.length

        let _arrJawabanDiisi = []
        listJawabaan?.map((val, key) => {
            if (val?.jawaban_id) {
                _arrJawabanDiisi.push(key)
            }
        })

        let _arrNomorJawaban = []
        for (let x = 0; x < Math.ceil(jumlahJawaban / colPerRow); x++) {
            let _temp = []
            for (let y = (x * colPerRow); y < ((x + 1) * colPerRow) && y < jumlahJawaban; y++) {
                _temp.push(y)
            }

            _arrNomorJawaban.push(_temp)
        }

        setArrJawabanDiisi(_arrJawabanDiisi)
        setArrNomorJawaban(_arrNomorJawaban)
    }

    const viewPertanyaan = () => {
        return <React.Fragment>
            <div className="card mb-2">
                <div className="card-header border-bottom py-3 border-grid-ujian">
                    <small className="text-muted text-uppercase">Kategori : {soal?.kategoriSoal?.nama || "-"}</small>
                </div>
            </div>
            <div className="card mb-4">
                <div className="card-header border-bottom py-3 border-grid-ujian">
                    <small className="text-muted text-uppercase">Nomor : {nomorSekarang}</small>
                </div>
                <div className="card-body box-ujian pt-4">
                    <span dangerouslySetInnerHTML={{ __html: soal?.pertanyaan }}></span>

                    {
                        opsiJawaban?.map((val, key) => {
                            return <React.Fragment key={key}>
                                <div className={"form-check " + ((key + 1) < opsiJawaban?.length ? "mb-2" : "")}>
                                    <input name={"soal" + soal?.id} value={val?.id} className="form-check-input" type="radio" id={"jawaban" + val?.id} tabIndex={key + 1} checked={jawaban === val?.id} onChange={(e) => pilihJawaban(e?.target?.value)} />
                                    <label className="form-check-label" htmlFor={"jawaban" + val?.id}>
                                        <span className="me-2">{val?.kode ? "[" + val?.kode + "]" : ""}</span> {val?.jawaban}
                                    </label>
                                </div>
                            </React.Fragment>
                        })
                    }

                    {
                        error ?
                            <p className="text-danger mb-0 mt-4">{error}</p>
                            : null
                    }
                </div>
            </div>
        </React.Fragment>
    }

    const viewTerisiSemua = () => {
        return <React.Fragment>
            <div className="card mb-4">
                <div className="card-body pt-4">
                    <p>Semua jawaban telah diisi, klik tombol Selesai untuk mengakhiri ujian.</p>
                </div>
            </div>

            <div className="d-block d-md-none">
                <button onClick={clickSelesai} className="btn btn-primary btn-block" type="button" disabled={selesaiSubmit === true && selesai?.loading === true}>
                    Selesai
                    {selesaiSubmit === true && selesai?.loading === true ? <span className="spinner-border ms-2" role="status" aria-hidden="true"></span> : null}
                </button>
            </div>
        </React.Fragment>
    }

    const viewNavbar = () => {
        return <React.Fragment>
            <nav className="layout-navbar container-xxl navbar navbar-expand-xl navbar-detached align-items-center bg-navbar-theme nav-mw-ujian" id="layout-navbar">
                <div className="navbar-nav-right d-flex align-items-center" id="navbar-collapse">
                    <div className="navbar-nav align-items-center">
                        <div className="nav-item navbar-search-wrapper mb-0">
                            <div className="nav-item mb-0 d-block">
                                Sisa Waktu <span className="fw-semibold text-primary">{asTimeWithLabel(waktuUjian) || "-"}</span>
                            </div>
                        </div>
                    </div>

                    <ul className="navbar-nav flex-row align-items-center ms-auto d-md-none d-block">
                        <li className="nav-item dropdown-shortcuts navbar-dropdown dropdown">
                            <a className="nav-link dropdown-toggle hide-arrow" href="/#;" onClick={onClickMenu} aria-expanded="false">
                                <i className="bx bx-grid-alt bx-sm"></i>
                                <span className="badge bg-danger badge-notifications">{prosesUjian?.jawaban?.length && arrJawabanDiisi?.length ? prosesUjian?.jawaban?.length - arrJawabanDiisi?.length : 0}</span>
                            </a>
                        </li>
                    </ul>
                </div>
            </nav>
        </React.Fragment>
    }

    return <React.Fragment>
        <div className="layout-page">
            {viewNavbar()}

            <div className="content-wrapper">
                <div className={menuShow === true ? "d-none" : "d-block"}>
                    <div className="container-xxl flex-grow-1 container-p-y container-mw-ujian">
                        {
                            selisihWaktuLoaded === true && fetchLoaded === true ?
                                <div className="row">
                                    <div className="col-md-8">
                                        {
                                            terisiSemua === true ?
                                                viewTerisiSemua() :
                                                viewPertanyaan()
                                        }
                                        <div className="d-none d-md-block">
                                            <div className="card mb-4">
                                                {viewFooter()}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="d-none d-md-block">
                                            {viewMenuNomor()}
                                        </div>
                                    </div>
                                </div>
                                :
                                <div className="auth-scan-cam-wrapper">
                                    <span className="spinner-border ms-2" role="status" aria-hidden="true"></span>
                                </div>
                        }
                    </div>

                    {
                        selisihWaktuLoaded === true && fetchLoaded === true ?
                            <div className="d-block d-md-none">
                                <div className="layout-footer-fixed">
                                    <footer className="content-footer footer bg-footer-theme">
                                        <div className="container-xxl py-2">
                                            {viewFooter()}
                                        </div>
                                    </footer>
                                </div>
                            </div>
                            : null
                    }
                </div>


                {
                    selisihWaktuLoaded === true && fetchLoaded === true ?
                        <div className={menuShow === true ? "d-block" : "d-none"}>
                            <div className="d-block d-md-none">
                                <div className="container-xxl flex-grow-1 container-p-y">
                                    {viewMenuNomor()}
                                </div>
                            </div>
                        </div>
                        : null
                }

                <div className="content-backdrop fade"></div>
            </div>
        </div>
    </React.Fragment>
}

const mapStateToProps = state => {
    const { accessToken } = state.auth
    const { ujian, prosesUjian, hasilUjian, selesai, proses } = state.ujian

    return { accessToken, ujian, prosesUjian, hasilUjian, selesai, proses }
}

export default withRouter(connect(mapStateToProps, { actionProsesUjian, actionSelesaiUjian, actionKirimJawabanUjian, actionResetProsesUjianSuccess })(ProsesUjian))