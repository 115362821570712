function parseJSON(response) {
  return response.json()
}

function checkStatus(response, options = null) {
  if (response.status >= 200 && response.status <= 300) {
    return response
  } else if (response.status === 401) {
    if (options?.headers?.Authorization) {
      // localStorage.clear()
      window.location.href = `${process.env.REACT_APP_SUBDIR}/sign-out`
    }
  }
  const error = new Error(response.statusText)
  error.response = response
  throw error
}

export function request(url, options) {
  return fetch(url, options)
    .then((res) => checkStatus(res, options))
    .then(parseJSON)
}