import React from "react"
import { Link } from "react-router-dom"
import { hasNotification } from "../../../utils/config"
import { initialName } from "../../../utils/formatter"
import AvatarByName from "../../AvatarByName"

const notificationList = [
    {
        name: "Jimmy S",
        initial: "JS",
        detail: "Peserta ujian melakukan daftar ulang",
        time: "2023-04-03 13:00"
    },
    {
        name: "Zacky V",
        initial: "JS",
        detail: "Peserta ujian melakukan daftar ulang",
        time: "2023-04-02 13:00"
    }
]

const Navbar = ({ user }) => {
    return <React.Fragment>
        <ul className="navbar-nav flex-row align-items-center ms-auto">
            {
                hasNotification === true ?
                    <li className="nav-item dropdown-notifications navbar-dropdown dropdown me-3 me-xl-1">
                        <a className="nav-link dropdown-toggle hide-arrow" href="/#" onClick={(e) => e.preventDefault()} data-bs-toggle="dropdown"
                            data-bs-auto-close="outside" aria-expanded="false">
                            <i className="bx bx-bell bx-sm"></i>
                            <span className="badge bg-danger rounded-pill badge-notifications">{notificationList?.length}</span>
                        </a>
                        <ul className="dropdown-menu dropdown-menu-end py-0">
                            <li className="dropdown-menu-header border-bottom">
                                <div className="dropdown-header d-flex align-items-center py-3">
                                    <h5 className="text-body mb-0 me-auto">Pemberitahuan</h5>
                                    {
                                        notificationList?.length > 0 ?
                                            <a href="/#" onClick={(e) => e.preventDefault()} className="dropdown-notifications-all text-body" data-bs-toggle="tooltip"
                                                data-bs-placement="top" title="Tandai semua sudah dibaca"><i className="bx fs-4 bx-envelope-open"></i></a>
                                            : null
                                    }
                                </div>
                            </li>
                            <li className="dropdown-notifications-list scrollable-container">
                                <ul className="list-group list-group-flush">
                                    {
                                        notificationList?.length > 0 ?
                                            notificationList?.map((val, key) => {
                                                return <li key={key} className="list-group-item list-group-item-action dropdown-notifications-item">
                                                    <div className="d-flex">
                                                        <div className="flex-shrink-0 me-3">
                                                            <div className="avatar">
                                                                <span className={"avatar-initial rounded-circle bg-label-" + (key % 2 === 0 ? "primary" : "secondary")}>{val?.initial}</span>
                                                            </div>
                                                        </div>
                                                        <div className="flex-grow-1">
                                                            <h6 className="mb-1">{val?.name}</h6>
                                                            <p className="mb-0">{val?.detail}</p>
                                                            <small className="text-muted">{val?.time}</small>
                                                        </div>
                                                        <div className="flex-shrink-0 dropdown-notifications-actions">
                                                            <a href="/#" onClick={(e) => e.preventDefault()} className="dropdown-notifications-read"><span
                                                                className="badge badge-dot"></span></a>
                                                            <a href="/#" onClick={(e) => e.preventDefault()} className="dropdown-notifications-archive"><span
                                                                className="bx bx-x"></span></a>
                                                        </div>
                                                    </div>
                                                </li>
                                            })
                                            : <li className="list-group-item list-group-item-action dropdown-notifications-item">
                                                Tidak ada pemberitahuan
                                            </li>
                                    }
                                </ul>
                            </li>
                            {
                                notificationList?.length > 0 ?
                                    <li className="dropdown-menu-footer border-top">
                                        <a href="/#" onClick={(e) => e.preventDefault()} className="dropdown-item d-flex justify-content-center p-3">
                                            Lihat semua
                                        </a>
                                    </li>
                                    : null
                            }
                        </ul>
                    </li> : null
            }
            <li className="nav-item navbar-dropdown dropdown-user dropdown">
                <a className="nav-link dropdown-toggle hide-arrow" href="/#" onClick={(e) => e.preventDefault()} data-bs-toggle="dropdown">
                    <div className="avatar avatar-online">
                        <AvatarByName name={initialName(user?.name)} />
                    </div>
                </a>
                <ul className="dropdown-menu dropdown-menu-end">
                    <li>
                        <a className="dropdown-item" href="pages-account-settings-account.html">
                            <div className="d-flex">
                                <div className="flex-shrink-0 me-3">
                                    <div className="avatar avatar-online">
                                        <AvatarByName name={initialName(user?.name)} />
                                    </div>
                                </div>
                                <div className="flex-grow-1">
                                    <span className="fw-semibold d-block">{user?.name}</span>
                                    <small className="text-muted">{user?.role}</small>
                                </div>
                            </div>
                        </a>
                    </li>
                    <li>
                        <div className="dropdown-divider"></div>
                    </li>
                    <li>
                        <Link to={`${process.env.REACT_APP_SUBDIR}/change-password`} className="dropdown-item">
                            <i className="bx bx-key me-2"></i>
                            <span className="align-middle">Ganti Password</span>
                        </Link>
                    </li>
                    <li>
                        <a href={`${process.env.REACT_APP_SUBDIR}/sign-out`} className="dropdown-item">
                            <i className="bx bx-power-off me-2"></i>
                            <span className="align-middle">Log Out</span>
                        </a>
                    </li>
                </ul>
            </li>
        </ul>
    </React.Fragment>
}

export default Navbar