import React, { useEffect } from 'react'
import { Link, withRouter } from 'react-router-dom'
import ScrollToTop from '../../ScrollToTop'
import IlsError from "../../../assets/img/illustrations/page-misc-error-light.png"

const ErrorLayout = (props) => {
    const { children } = props

    useEffect(() => {
        return () => window.location.reload(false);
    }, [])

    return <React.Fragment>
        <ScrollToTop />
        <div className="container-xxl container-p-y">
            <div className="misc-wrapper">
                {children}
                <Link to={ process.env.REACT_APP_SUBDIR + "/"} className="btn btn-primary">Kembali</Link>
                <div className="mt-3">
                    <img
                        src={IlsError}
                        alt="page-misc-error-light"
                        width="500"
                        className="img-fluid"
                        data-app-dark-img="illustrations/page-misc-error-dark.png"
                        data-app-light-img="illustrations/page-misc-error-light.png"
                    />
                </div>
            </div>
        </div>
    </React.Fragment>
}

export default withRouter(ErrorLayout)