import React, { useEffect, useState } from "react"
import { changeBreadcrumbs, changeTitle } from "../utils/page"
import IlsBeranda from "../assets/img/illustrations/man-with-laptop-light.png"
import { Link, withRouter } from "react-router-dom"
import { connect } from "react-redux"
import { actionFetchUjian } from '../store/ujian/actions'
import { asTimeWithLabel, dateTime, strtotime } from "../utils/formatter"
import { request } from "../utils/request"

const waktuStatic = 1681102603

const Beranda = (props) => {
    const { accessToken, user, ujian, hasilUjian, actionFetchUjian, success } = props
    const [waktuMenujuUjian, setWaktuMenujuUjian] = useState(null)
    const [stageNow, setStageNow] = useState(2)

    const [waktuSekarang, setWaktuSekarang] = useState(null)
    const [selisihWaktu, setSelisihWaktu] = useState(null)
    const [selisihWaktuLoaded, setSelisihWaktuLoaded] = useState(false)
    const [fetchCalled, setFetchCalled] = useState(false)
    const [fetchLoaded, setFetchLoaded] = useState(false)

    useEffect(() => {
        const titlePage = "Beranda"
        changeTitle(titlePage)
        changeBreadcrumbs(titlePage)

        actionGetWaktuServer()
    }, [])

    const actionGetWaktuServer = async () => {
        setSelisihWaktuLoaded(false)

        // let now = waktuStatic
        let now = parseInt((new Date).getTime() / 1000)
        // console.log(dateTime(now * 1000, "YYYY-MM-DD HH:mm:ss"))
        try {
            const res = await request(process.env.REACT_APP_SERVICE_UJIAN + '/waktu-server', {
                method: 'GET'
            })

            if (res?.success === true) {
                // setSelisihWaktu(waktuStatic + 2 - now)
                setSelisihWaktu(res?.result - now)
                setSelisihWaktuLoaded(true)
                setWaktuSekarang(res?.result)
            } else {
                setSelisihWaktu(0)
                setSelisihWaktuLoaded(true)
            }
        } catch (error) {
            setSelisihWaktu(0)
            setSelisihWaktuLoaded(true)
        }
    }

    useEffect(() => {
        if (selisihWaktu !== null && fetchCalled === false) {
            setFetchCalled(true)
            actionFetchUjian({ accessToken })
        }
    }, [selisihWaktu])

    useEffect(() => {
        if (waktuSekarang !== null) {
            const intervalId = setInterval(() => {
                let now = waktuSekarang + 1
                setWaktuSekarang(now)
            }, 1000)

            return () => clearInterval(intervalId)
        }
    }, [waktuSekarang])

    useEffect(() => {
        if (fetchCalled === true) {
            if (success !== null) {
                setFetchLoaded(true)
            }
        }
    }, [success, fetchCalled])

    useEffect(() => {
        if (ujian?.sesiUjian?.waktu_mulai) {
            // let now = waktuStatic + selisihWaktu
            let now = parseInt((new Date).getTime() / 1000) + selisihWaktu

            let sisaWaktuMenujuUjian = ((strtotime(ujian?.sesiUjian?.waktu_mulai, "YYYY-MM-DD HH:mm:ss") / 1000) - now)

            setWaktuMenujuUjian(sisaWaktuMenujuUjian)
        }
    }, [ujian?.sesiUjian?.waktu_mulai])

    useEffect(() => {
        if (hasilUjian?.status === "Ujian Selesai") {
            setStageNow(5)
            return
        }
        if (waktuMenujuUjian !== null && waktuMenujuUjian < 1) {
            // let now = waktuStatic + selisihWaktu
            let now = parseInt((new Date).getTime() / 1000) + selisihWaktu

            if (waktuMenujuUjian < 1 && now > (strtotime(ujian?.sesiUjian?.waktu_selesai, "YYYY-MM-DD HH:mm:ss") / 1000)) {
                setStageNow(5)
                return
            } else {
                setStageNow(4)
                return
            }
        }
        if (waktuMenujuUjian === null) return
        if (!waktuMenujuUjian) return
        if (waktuMenujuUjian > 0 && waktuMenujuUjian < (60 * 30)) {
            setStageNow(3)
        }

        const intervalId = setInterval(() => {
            // let now = waktuStatic + selisihWaktu
            let now = parseInt((new Date).getTime() / 1000) + selisihWaktu
            let sisaWaktuMenujuUjian = ((strtotime(ujian?.sesiUjian?.waktu_mulai, "YYYY-MM-DD HH:mm:ss") / 1000) - now)

            setWaktuMenujuUjian(sisaWaktuMenujuUjian)
        }, 1000)

        return () => clearInterval(intervalId)
    }, [waktuMenujuUjian, hasilUjian?.status])

    return <React.Fragment>
        <div className="container-xxl flex-grow-1 container-p-y">
            {
                selisihWaktuLoaded === true && fetchLoaded === true ?
                    ujian?.sesiUjian ?
                        <React.Fragment>
                            <div className="row">
                                <div className="col-lg-12 mb-3 order-0">
                                    <div className="card">
                                        <div className="d-flex align-items-end row">
                                            <div className="col-sm-7">
                                                <div className="card-body">
                                                    <h5 className="card-title text-primary">Selamat Datang{" " + user?.name}! 🎉</h5>
                                                    {
                                                        stageNow >= 5 ?
                                                            <p className="mb-4">Sesi ujian selesai</p>
                                                            :
                                                            stageNow >= 4 ?
                                                                <p className="mb-4">Sesi ujian dimulai</p>
                                                                :
                                                                stageNow >= 3 ?
                                                                    <React.Fragment>
                                                                        <p className="mt-4 mb-0"><strong>{asTimeWithLabel(waktuMenujuUjian)}</strong> menuju sesi ujian anda.</p>
                                                                        <p className="mb-4">Sesi persiapan ujian dimulai, silahkan masuk ke halaman ujian.</p>
                                                                    </React.Fragment>
                                                                    :
                                                                    stageNow >= 1 ?
                                                                        <React.Fragment>
                                                                            <p className="mt-4 mb-0"><strong>{asTimeWithLabel(waktuMenujuUjian)}</strong> menuju sesi ujian anda.</p>
                                                                            <p className="mb-4" style={{ fontSize: "1.1rem" }}>Wajib bergabung untuk persiapan ujian <strong>30 menit</strong> sebelum ujian dimulai.</p>
                                                                        </React.Fragment>
                                                                        : null
                                                    }
                                                    {
                                                        stageNow >= 5 ?
                                                            <Link to={process.env.REACT_APP_SUBDIR + "/hasil-ujian"} className="btn btn-sm btn-label-success">Hasil Ujian</Link> :
                                                            <Link to={process.env.REACT_APP_SUBDIR + "/ujian"} className="btn btn-sm btn-label-primary">Halaman Ujian</Link>
                                                    }
                                                </div>
                                            </div>
                                            <div className="col-sm-5 text-center text-sm-left">
                                                <div className="card-body pb-0 px-0 px-md-4">
                                                    <img src={IlsBeranda} height="140" alt="View Badge User" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="card mb-3">
                                <div className="card-body py-2">
                                    <marquee><small className="text-muted text-uppercase">WASPADA TERHADAP PENIPUAN, SELURUH TAHAPAN PROSES SELEKSI INI TIDAK DIPUNGUT BIAYA APAPUN</small></marquee>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-xl-4 col-lg-4 col-md-4">
                                    <div className="card mb-4">
                                        <div className="card-body">
                                            <small className="text-muted text-uppercase">Informasi Akun</small>
                                            <ul className="list-unstyled mb-0 mt-3">
                                                <li className="d-flex align-items-center mb-3"><i className="bx bx-user"></i><span className="fw-semibold mx-2">Nama:</span> <span>{user?.name || '-'}</span></li>
                                                <li className="d-flex align-items-center mb-3"><i className="bx bx-detail"></i><span className="fw-semibold mx-2">NIK:</span> <span>{ujian?.peserta?.nik || '-'}</span></li>
                                                <li className="d-flex align-items-center mb-3"><i className="bx bx-envelope"></i><span className="fw-semibold mx-2">Email:</span> <span>{user?.email || '-'}</span></li>
                                                <li className="d-flex align-items-center mb-3"><i className="bx bx-phone"></i><span className="fw-semibold mx-2">5 Digit Belakang Nomor HP:</span> <span>{ujian?.peserta?.nomor_hp || '-'}</span></li>
                                                <li className="d-flex align-items-center mb-2"><i className="bx bx-check"></i><span className="fw-semibold mx-2">Status:</span> <span>{ujian?.peserta?.statusPeserta || '-'}</span></li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="card mb-4">
                                        <div className="card-body">
                                            <small className="text-muted text-uppercase">Informasi Ujian</small>
                                            <ul className="list-unstyled mb-0 mt-3">
                                                <li className="d-flex align-items-center mb-3"><i className="bx bx-time"></i><span className="fw-semibold mx-2">Tanggal Ujian:</span> <span>{dateTime(strtotime(ujian?.sesiUjian?.waktu_mulai), "YYYY-MM-DD") || '-'}</span></li>
                                                <li className="d-flex align-items-center mb-3"><i className="bx bx-time"></i><span className="fw-semibold mx-2">Waktu Mulai:</span> <span>{dateTime(strtotime(ujian?.sesiUjian?.waktu_mulai), "HH:mm") || '-'}</span></li>
                                                <li className="d-flex align-items-center mb-3"><i className="bx bx-time-five"></i><span className="fw-semibold mx-2">Waktu Selesai:</span> <span>{dateTime(strtotime(ujian?.sesiUjian?.waktu_selesai), "HH:mm") || '-'}</span></li>
                                                <li className="d-flex align-items-center mb-2"><i className="bx bx-detail"></i><span className="fw-semibold mx-2">Jumlah Soal:</span> <span>{ujian?.ujianPeserta?.jumlah_soal || '-'}</span></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-xl-4 col-lg-4 col-md-4">
                                    <div className="card card-action mb-4">
                                        <div className="card-header align-items-center">
                                            <small className="text-muted text-uppercase">Jadwal Peserta</small>
                                        </div>
                                        <div className="card-body">
                                            <ul className="timeline ms-2">
                                                <li className="timeline-item timeline-item-transparent">
                                                    <span className={"timeline-point timeline-point-" + (stageNow >= 5 ? "primary" : "secondary")}></span>
                                                    <div className="timeline-event">
                                                        <div className="timeline-header mb-1">
                                                            <h6 className="mb-0">Ujian Selesai</h6>
                                                        </div>
                                                        {
                                                            stageNow >= 5 ?
                                                                <p className="mb-1">{hasilUjian?.waktu_selesai || ujian?.sesiUjian?.waktu_selesai}</p>
                                                                :
                                                                <p className="mb-1">-</p>
                                                        }
                                                    </div>
                                                </li>
                                                <li className="timeline-item timeline-item-transparent">
                                                    <span className={"timeline-point timeline-point-" + (stageNow >= 4 ? "primary" : "secondary")}></span>
                                                    <div className="timeline-event">
                                                        <div className="timeline-header mb-1">
                                                            <h6 className="mb-0">Ujian</h6>
                                                        </div>
                                                        <p className="mb-1">{ujian?.jadwalKegiatan?.ujian || '-'}</p>
                                                    </div>
                                                </li>
                                                <li className="timeline-item timeline-item-transparent">
                                                    <span className={"timeline-point timeline-point-" + (stageNow >= 3 ? "primary" : "secondary")}></span>
                                                    <div className="timeline-event">
                                                        <div className="timeline-header mb-1">
                                                            <h6 className="mb-0">Persiapan Ujian</h6>
                                                        </div>
                                                        <p className="mb-1">Silahkan login 30 menit sebelum dimulai</p>
                                                    </div>
                                                </li>
                                                <li className="timeline-item timeline-item-transparent">
                                                    <span className={"timeline-point timeline-point-" + (stageNow >= 2 ? "primary" : "secondary")}></span>
                                                    <div className="timeline-event">
                                                        <div className="timeline-header mb-1">
                                                            <h6 className="mb-0">Daftar Ulang</h6>
                                                        </div>
                                                        <p className="mb-1">{ujian?.jadwalKegiatan?.daftarUlang || '-'}</p>
                                                    </div>
                                                </li>
                                                <li className="timeline-item timeline-item-transparent">
                                                    <span className={"timeline-point timeline-point-" + (stageNow >= 1 ? "primary" : "secondary")}></span>
                                                    <div className="timeline-event pb-2">
                                                        <div className="timeline-header mb-1">
                                                            <h6 className="mb-0">Di daftarkan oleh Admin</h6>
                                                        </div>
                                                        <p className="mb-1">{ujian?.jadwalKegiatan?.daftarAdmin || '-'}</p>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-xl-4 col-lg-4 col-md-4">
                                    <div className="card card-action mb-4">
                                        <div className="card-header align-items-center">
                                            <small className="text-muted text-uppercase">Informasi Penting</small>
                                        </div>
                                        <div className="card-body">
                                            <ul>
                                                <li className="mb-3">
                                                    Pastikan waktu perangkat sudah sesuai dengan waktu sistem
                                                </li>
                                                <li className="mb-3">
                                                    Waktu sistem: <strong>{waktuSekarang ? dateTime(waktuSekarang * 1000, "YYYY-MM-DD HH:mm:ss") : null}</strong>
                                                </li>
                                                <li className="mb-3">
                                                    Hubungi petugas jika terjadi kendala disertai dengan screenshoot kendala
                                                </li>
                                                <li className="mb-3">
                                                    Pastikan javascript browser aktif
                                                </li>
                                                <li>
                                                    Pastikan internet dalam keadaan bagus
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </React.Fragment>
                        :
                        <div className="row">
                            <div className="col-lg-12 mb-4 order-0">
                                <div className="card">
                                    <div className="d-flex align-items-end row">
                                        <div className="col-sm-7">
                                            <div className="card-body">
                                                <h5 className="card-title text-primary">Selamat Datang{" " + user?.name}! 🎉</h5>
                                                <p className="mb-0">Anda belum mendapatkan sesi ujian</p>
                                                <p className="mb-3">Silahkan tunggu proses penetapan sesi ujian oleh admin</p>
                                            </div>
                                        </div>
                                        <div className="col-sm-5 text-center text-sm-left">
                                            <div className="card-body pb-0 px-0 px-md-4">
                                                <img src={IlsBeranda} height="140" alt="View Badge User" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    :
                    <div className="auth-scan-cam-wrapper">
                        <span className="spinner-border ms-2" role="status" aria-hidden="true"></span>
                    </div>
            }
        </div>
    </React.Fragment>
}

const mapStateToProps = state => {
    const { user, accessToken } = state.auth
    const { ujian, hasilUjian, success } = state.ujian

    return {
        accessToken,
        user,
        ujian,
        hasilUjian,
        success
    }
}

export default withRouter(connect(mapStateToProps, { actionFetchUjian })(Beranda))