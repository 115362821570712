import React, { useEffect, useState } from "react"
import UseCss from "../../UseCss"

const Step = ({ items }) => {
    const [show, setShow] = useState(false)

    UseCss({ src: "/assets/vendor/libs/bs-stepper/bs-stepper.css" })

    useEffect(() => {
        setTimeout(() => setShow(true), 300)
    }, [])

    return <React.Fragment>
        {
            show === true ?
                <div className="bs-stepper-header border-bottom-0">
                    {
                        items?.map((val, key) => {
                            return <React.Fragment key={key}>
                                <div className={"step " + (val?.active === true ? "active" : "")}>
                                    <button type="button" className="step-trigger" aria-selected={val?.active === true ? "true" : "false"} disabled={val?.disabled === true ? "disabled" : ""}>
                                        <span className="bs-stepper-circle"><i className={val?.icon}></i></span>
                                        <span className="bs-stepper-label mt-1">
                                            <span className="bs-stepper-title">{val?.label}</span>
                                            <span className="bs-stepper-subtitle">{val?.detail}</span>
                                        </span>
                                    </button>
                                </div>
                                {
                                    key + 1 < items?.length ?
                                        <div className="line">
                                            <i className="bx bx-chevron-right"></i>
                                        </div> : null
                                }
                            </React.Fragment>
                        })
                    }
                </div> : null
        }
    </React.Fragment>
}

export default Step