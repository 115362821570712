import { takeEvery, put, all, call } from 'redux-saga/effects'
import actionTypes from './actionTypes'
import { request } from '../../utils/request'
import { actionFetchUjianStart, actionFetchUjianEnd, actionFetchUjianFailed, actionFetchUjianSuccess, actionRefreshTokenSuccess, actionVerifikasiUjianStart, actionVerifikasiUjianSuccess, actionVerifikasiUjianFailed, actionVerifikasiUjianEnd, actionProsesUjianSuccess, actionProsesUjianFailed, actionProsesUjianEnd, actionProsesUjianStart, actionSelesaiUjianEnd, actionSelesaiUjianFailed, actionSelesaiUjianSuccess, actionSelesaiUjianStart, actionKirimJawabanUjianSuccess } from './actions'

function* sagaFetchUjian(params) {
  yield put(actionFetchUjianStart())

  const { data: { accessToken, fetchWajah = false } } = params
  const header = {
    "Content-Type": "application/json",
    "Authorization": `Bearer ${accessToken}`
  }

  try {
    let url = `${process.env.REACT_APP_SERVICE_UJIAN}/fetch`
    if (fetchWajah === true) {
      url = `${process.env.REACT_APP_SERVICE_UJIAN}/fetch?fetchWajah=1`
    }
    const res = yield call(request, url, {
      method: 'GET',
      headers: header
    })

    if (res?.success === true) {
      yield put(actionFetchUjianSuccess({
        ujian: {
          jadwalKegiatan: res?.result?.jadwalKegiatan,
          peserta: res?.result?.peserta,
          sesiUjian: res?.result?.sesiUjian,
          ujianPeserta: res?.result?.ujianPeserta
        },
        hasilUjian: res?.result?.hasilUjian
      }))
    }
  } catch (err) {
    yield put(actionFetchUjianFailed())
  }

  yield put(actionFetchUjianEnd())
}

function* sagaVerifikasiUjian(params) {
  yield put(actionVerifikasiUjianStart())

  const { data: { accessToken, token_wajah } } = params
  const header = {
    "Content-Type": "application/json",
    "Authorization": `Bearer ${accessToken}`
  }

  let payload = {
    token_wajah
  }

  try {
    let url = `${process.env.REACT_APP_SERVICE_UJIAN}/verifikasi`
    const res = yield call(request, url, {
      method: 'POST',
      headers: header,
      body: JSON.stringify(payload)
    })

    if (res?.success === true) {
      yield put(actionVerifikasiUjianSuccess(res?.result?.jawabanUjianPeserta))
    } else {
      yield put(actionVerifikasiUjianFailed())
    }
  } catch (err) {
    yield put(actionVerifikasiUjianFailed())
  }

  yield put(actionVerifikasiUjianEnd())
}

function* sagaProsesUjian(params) {
  yield put(actionProsesUjianStart())

  const { data: { accessToken, jawaban } } = params
  const header = {
    "Content-Type": "application/json",
    "Authorization": `Bearer ${accessToken}`
  }

  try {
    let url = `${process.env.REACT_APP_SERVICE_UJIAN}/proses`
    const res = yield call(request, url, {
      method: 'GET',
      headers: header
    })

    if (res?.success === true) {
      // let resJawaban = res?.result?.jawabanUjianPeserta
      // resJawaban?.map(val => {
      //   if (val?.jawaban_id) {
      //     let index = jawaban?.findIndex(x => x?.soal?.id === val?.soal?.id)
      //     jawaban[index] = val
      //   }
      // })

      yield put(actionProsesUjianSuccess({
        waktu_mulai: res?.result?.ujianPeserta?.waktu_mulai,
        jawaban: res?.result?.jawabanUjianPeserta
      }))
    } else {
      yield put(actionProsesUjianFailed())
    }
  } catch (err) {
    yield put(actionProsesUjianFailed())
  }

  yield put(actionProsesUjianEnd())
}

function* sagaSelesaiUjian(params) {
  yield put(actionSelesaiUjianStart())

  const { data: { accessToken, jawaban } } = params
  const header = {
    "Content-Type": "application/json",
    "Authorization": `Bearer ${accessToken}`
  }

  let payloadJawaban = []
  jawaban?.map((val, key) => {
    payloadJawaban.push({
      soal_id: val?.soal?.id,
      jawaban_id: val?.jawaban_id
    })
  })

  let payload = {
    jawaban: payloadJawaban
  }

  try {
    let url = `${process.env.REACT_APP_SERVICE_UJIAN}/selesai`
    const res = yield call(request, url, {
      method: 'POST',
      headers: header,
      body: JSON.stringify(payload)
    })

    if (res?.success === true) {
      yield put(actionSelesaiUjianSuccess({
        waktu_selesai: res?.result?.hasilUjian?.waktu_selesai,
        hasilUjian: res?.result?.hasilUjian
      }))
    } else {
      yield put(actionSelesaiUjianFailed())
    }
  } catch (err) {
    yield put(actionSelesaiUjianFailed())
  }

  yield put(actionSelesaiUjianEnd())
}

function* sagaKirimJawabanUjian(params) {
  const { data: { accessToken, jawaban, soal_id, jawaban_id } } = params

  let index = jawaban?.findIndex(val => val?.soal?.id === soal_id)
  jawaban[index] = {
    ...jawaban[index],
    jawaban_id
  }

  try {
    yield put(actionKirimJawabanUjianSuccess(jawaban))
    yield call(request, `${process.env.REACT_APP_SERVICE_UJIAN}/kirim-jawaban`, {
      method: 'POST',
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${accessToken}`
      },
      body: JSON.stringify({ soal_id, jawaban_id })
    })
  } catch (error) { }
}

function* authSaga() {
  yield all([
    takeEvery(actionTypes.FETCH_UJIAN, sagaFetchUjian),
    takeEvery(actionTypes.VERIFIKASI_UJIAN, sagaVerifikasiUjian),
    takeEvery(actionTypes.PROSES_UJIAN, sagaProsesUjian),
    takeEvery(actionTypes.KIRIM_JAWABAN_UJIAN, sagaKirimJawabanUjian),
    takeEvery(actionTypes.SELESAI_UJIAN, sagaSelesaiUjian)

  ])
}

export default authSaga