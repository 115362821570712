import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { Link, withRouter } from "react-router-dom"
import { changeBreadcrumbs, changeTitle } from "../utils/page"
import { alert } from "../utils/alert"
import { pushUrl } from "../utils/url"
import { request } from "../utils/request"
import PasswordInput from "../components/Form/PasswordInput"

const ChangePassword = (props) => {
    const { accessToken } = props

    const [payload, setPayload] = useState({
        old_password: "",
        new_password: "",
        new_password_confirm: ""
    })
    const [errors, setErrors] = useState({})
    const [success, setSuccess] = useState(null)
    const [submit, setSubmit] = useState(false)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        const titlePage = "Ganti Password"
        changeTitle(titlePage)
        changeBreadcrumbs(titlePage)
    }, [])

    useEffect(async () => {
        if (submit === true) {
            if (success === true) {
                setPayload({
                    old_password: "",
                    new_password: "",
                    new_password_confirm: ""
                })
            }
        }
    }, [success, submit])

    const onChange = ((field, value) => {
        payload[field] = value?.slice(0, 10)

        setPayload({
            ...payload,
        })
    })

    const onSubmit = (e) => {
        if (validate() === true) {
            setSubmit(true)
            actionSubmit(payload)
        }

        e.preventDefault()
    }

    const actionSubmit = async (payload) => {
        setLoading(true)

        try {
            const headerPost = {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${accessToken}`
            }
            const res = await request(process.env.REACT_APP_SERVICE_AUTH + '/change-password', {
                method: 'POST',
                headers: headerPost,
                body: JSON.stringify(payload)
            })

            if (res?.success === true) {
                setSuccess(true)
                alert({ title: "Berhasil", html: res?.message })
            } else {
                setSuccess(false)
                setErrors(res?.errors)
                alert({ title: "Gagal", html: res?.message })
            }
        }
        catch (error) {
            setSuccess(false)
            alert({ title: "Gagal", html: "Gagal daftar, terjadi kesalahan di sisi server." })
        }

        setLoading(false)
    }

    const validate = () => {
        let jsonErrors = {}
        let hasError = false

        if (!payload?.old_password || payload?.old_password === "") {
            hasError = true
            jsonErrors["old_password"] = "Password Lama harus diisi"
        }
        if (!payload?.new_password || payload?.new_password === "") {
            hasError = true
            jsonErrors["new_password"] = "Password Baru harus diisi"
        }
        if (!payload?.new_password_confirm || payload?.new_password_confirm === "") {
            hasError = true
            jsonErrors["new_password_confirm"] = "Konfirmasi Password Baru harus diisi"
        }

        if (payload?.new_password && payload?.new_password_confirm) {
            if (payload?.new_password !== payload?.new_password_confirm) {
                hasError = true
                jsonErrors["new_password"] = ""
                jsonErrors["new_password_confirm"] = "Password baru tidak sama"
            }
        }

        setErrors(jsonErrors)

        if (hasError === true) {
            return false
        } else {
            return true
        }
    }

    return <React.Fragment>
        <div className="container-xxl flex-grow-1 container-p-y">
            <div className="card">
                <div className="card-body">
                    <form onSubmit={onSubmit} >
                        <div className="row">
                            <div className="col-lg-6">
                                <PasswordInput
                                    id={"old_password"}
                                    name={"old_password"}
                                    placeholder={"Masukkan password lama"}
                                    label={"Password Lama"}
                                    onChange={(e) => onChange('old_password', e?.target?.value)}
                                    required={true}
                                    value={payload?.old_password}
                                    error={errors?.old_password}
                                    errors={errors?.old_password}
                                    tabIndex={1} />

                                <PasswordInput
                                    id={"new_password"}
                                    name={"new_password"}
                                    placeholder={"Masukkan password baru"}
                                    label={"Password Baru"}
                                    onChange={(e) => onChange('new_password', e?.target?.value)}
                                    required={true}
                                    value={payload?.new_password}
                                    error={errors?.new_password}
                                    errors={errors?.new_password}
                                    tabIndex={2} />

                                <PasswordInput
                                    id={"new_password_confirm"}
                                    name={"new_password_confirm"}
                                    placeholder={"Masukkan ulang password baru"}
                                    label={"Konfirmasi password baru"}
                                    onChange={(e) => onChange('new_password_confirm', e?.target?.value)}
                                    required={true}
                                    value={payload?.new_password_confirm}
                                    error={errors?.new_password_confirm}
                                    errors={errors?.new_password_confirm}
                                    tabIndex={3} />

                                <button
                                    tabIndex={4}
                                    type="submit" className="btn btn-primary btn-md-block" disabled={loading === true}>
                                    Kirim
                                    {loading === true ? <span className="spinner-border ms-2" role="status" aria-hidden="true"></span> : null}
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </React.Fragment>
}

const mapStateToProps = state => {
    const { auth: { accessToken } } = state

    return { accessToken }
}

export default withRouter(connect(mapStateToProps, {})(ChangePassword))